import {GroupedData} from './groupByType';
import {sortTotals} from 'app/utils/line/sortTotals';


export const sortGroupedData = (groupedData: GroupedData): GroupedData => {
    const sortedGroupedData: GroupedData = {};

    Object.keys(groupedData).forEach(key => {
        if (key in groupedData) {
            sortedGroupedData[key] = sortTotals(groupedData[key]);
        }
    });

    return sortedGroupedData;
};
