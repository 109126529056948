import React from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastContent from 'app/components/toasts/Contents/ToastContent';
import BaseToast from 'app/components/toasts/BaseToast';
import {MAIN_CONTAINER_ID} from 'app/utils/Consts';
import I18n from 'app/i18n';
import {goToRoute} from 'app/utils';
import {getTableLinkPath} from 'app/utils/link_helper';
import {showPopup} from 'app/utils/modals/ModalsService';
import PopupFreespins from 'app/components/my_profile/freespins/PopupFreespins';

export default class FreeSpinToast extends BaseToast {
    onClick = (): void => {
        goToRoute(getTableLinkPath('/casino'));
        showPopup('popup-freespins', () => <PopupFreespins popupId={'popup-freespins'} free_spin_data={this.props.data.payload}/>);
    };

    notify = (): React.ReactText => toast.info(<ToastContent
        content={I18n.t('casino_free_spins.toast', {game: this.props.data.payload.game})}>
        <use xlinkHref="#svg-notif-system"/>
    </ToastContent>, this.options({closeOnClick: false, containerId: MAIN_CONTAINER_ID}));
}
