import BaseToast from 'app/components/toasts/BaseToast';
import {CloseButton} from 'app/components/toasts/CloseButton';
import SiteNotificationContent from 'app/components/toasts/Contents/SiteNotificationContent';
import {siteNotificationStore} from 'app/store/global';
import React from 'react';
import {toast} from 'react-toastify';
import {MAIN_CONTAINER_ID} from 'app/utils/Consts';

export default class SiteNotificationToast extends BaseToast {
    rootToastElement: React.RefObject<SiteNotificationContent> = React.createRef();

    onClose = (): void => {
        siteNotificationStore
            .sendLog(this.props.data.data.id, 'close')
            .catch();
    };

    handleOpen = (): void => {
        this.playSound();
    };

    notify = (): React.ReactText => {
        const notification = this.props.data.data;
        const close_time = 5000;

        return toast.warning(<SiteNotificationContent ref={this.rootToastElement} notification={notification}/>,
            this.options({
                autoClose: notification.require_interaction ? false : close_time,
                className: 'Toastify__toast--message',
                closeButton: <CloseButton onClick={this.onClose}/>,
                closeOnClick: false,
                containerId: MAIN_CONTAINER_ID,
                onClose: this.onClose,
                onOpen: this.handleOpen
            }));
    };
}
