import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import {getPromoLinkPath} from 'app/utils';
import {i18n_t} from 'app/i18n';
import ScatterFAQ from 'app/components/promo_action/modules/scatter_faq';
import {goToMyHero} from 'app/utils/app_links';
import ConvertButton from 'app/components/my_profile/artifacts/artifact_popup/compendium/ConvertButton';

interface AsideLinksProps {
    predictions_available: number
    prediction_points: number
    scatterText: string
}

export default class AsideLinks extends PureComponent<AsideLinksProps> {
    render(): React.ReactNode {
        const {prediction_points, predictions_available, scatterText} = this.props;

        return <div className="compendium-aside__links">
            <div className="compendium-aside__link">
                <svg height="16px" width="16px">
                    <use xlinkHref="#svg-predictions"/>
                </svg>
                <Link
                    to={getPromoLinkPath('/predictions')}
                >
                    {`${i18n_t('compendium.compendium_table')} (${predictions_available})`}
                </Link>
            </div>
            <div className="compendium-aside__link">
                <svg height="16px" width="16px">
                    <use xlinkHref="#svg-my-predictions"/>
                </svg>
                <Link
                    to={getPromoLinkPath('/my_predictions')}
                >
                    {i18n_t('compendium.my_predictions', {count: prediction_points})}
                </Link>
            </div>
            <div className="compendium-aside__link">
                <svg height="16px" width="16px">
                    <use xlinkHref="#svg-table-leaders"/>
                </svg>
                <Link
                    to={getPromoLinkPath('/rating')}
                >
                    {i18n_t('compendium.results_table')}
                </Link>
            </div>
            <div className="compendium-aside__link">
                <svg height="16px" width="16px">
                    <use xlinkHref="#svg-scatter"/>
                </svg>
                <ConvertButton onClickHandler={goToMyHero} classes={['compendium-aside__scatter-btn']}/>
                <ScatterFAQ text={scatterText}/>
            </div>
        </div>;
    }
}
