import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import FreespinInterface from 'app/interfaces/Freespinsinterface';
import {
    getActivateClickHandler,
    getCancelClickHandler,
    getDurationText
} from 'app/helpers/free_spin_helpers';
import {userBonusesStore} from 'app/store/global';

interface PopupFreespinsProps {
    popupId: string;
    free_spin_data: FreespinInterface;
}

export default class PopupFreespins extends PureComponent<PopupFreespinsProps> {
    render(): ReactNode {
        const spin = this.props.free_spin_data;
        return (
            <PopupDialog id={this.props.popupId} setFocus>
                <div className="popup__content">
                    <div className="freespins-congratulation">
                        <div className="freespins-congratulation__header">
                            <span className="span-title">
                                {I18n.t('casino_free_spins.popup_notice', {count: spin.quantity})}
                            </span>
                            <span className="span-title">{spin.game}</span>
                        </div>
                        <div className="freespins-congratulation__body">
                            <div className="freespins-timer">
                                <span className="freespins-timer-title">{I18n.t('expiring_in')}</span>
                                <span className="freespins-timer-count">{getDurationText(spin.time)}</span>
                            </div>
                            <div className="freespins-buttons">
                                <button
                                    className="button-adaptive button-adaptive_uppercase"
                                    onClick={getActivateClickHandler(spin.game_id, {casino_page: true})}
                                >
                                    {I18n.t('go_game')}
                                </button>
                                <button
                                    className="button-adaptive button-adaptive_crystal"
                                    onClick={getCancelClickHandler(spin, userBonusesStore)}
                                >
                                    {I18n.t('casino_free_spins.cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </PopupDialog>
        );
    }
}
