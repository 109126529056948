import {MouseEvent} from 'react';
import I18n from 'app/i18n';
import {modalConfirm} from 'app/utils/modals/popups';
import {startCasinoGame} from 'app/services/CasinoServices';
import {hideLastPopup} from 'app/utils/modals/ModalsService';
import FreespinInterface from 'app/interfaces/Freespinsinterface';
import {UserBonusesStore} from 'app/store/UserBonusesStore';
import {goToRoute} from 'app/utils';
import {getTableLinkPath} from 'app/utils/link_helper';

const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const RADIX = 10;

export const handleActivateClick = (
    e: MouseEvent<HTMLButtonElement>,
    gameId: string,
    options: { casino_page?: boolean } = {}
): void => {
    e.preventDefault();
    if (options.casino_page) {
        hideLastPopup();
    } else {
        goToRoute(getTableLinkPath('/casino'));
    }
    startCasinoGame(Number(gameId));
};


export const handleCancelClick = (
    e: MouseEvent<HTMLButtonElement>,
    spin: FreespinInterface,
    userBonusesStore: UserBonusesStore
): void => {
    e.preventDefault();
    modalConfirm(
        I18n.t('casino_free_spins.confirm_deactivate', {count: spin.quantity, name: spin.game}),
        () => userBonusesStore.cancelFreeSpin(spin.id)
    );
};


export const getActivateClickHandler = (
    gameId: string,
    options: { casino_page?: boolean } = {}
) => (e: MouseEvent<HTMLButtonElement>): void => handleActivateClick(e, gameId, options);

export const getCancelClickHandler = (
    spin: FreespinInterface,
    userBonusesStore: UserBonusesStore
) => (e: MouseEvent<HTMLButtonElement>): void => handleCancelClick(e, spin, userBonusesStore);


export const getDurationText = (time: { days: string; hours: string; minutes: string }): string => {
    const {days, hours, minutes} = time;

    if (Number(days) !== 0) {
        return I18n.t('casino_free_spins.duration_full', {days, hours, minutes});
    } else if (Number(hours) !== 0) {
        return I18n.t('casino_free_spins.duration_hours_minutes', {hours, minutes});
    }
    return I18n.t('casino_free_spins.duration_minutes', {minutes});
};

export const updateSpinTime = (spin: FreespinInterface): FreespinInterface | null => {
    const {days, hours, minutes} = spin.time;

    const totalMinutes =
        parseInt(days, RADIX) * HOURS_IN_DAY * MINUTES_IN_HOUR +
        parseInt(hours, RADIX) * MINUTES_IN_HOUR +
        parseInt(minutes, RADIX);

    if (totalMinutes <= 0) {
        return null;
    }

    const newTotalMinutes = totalMinutes - 1;
    const newDays = Math.floor(newTotalMinutes / (HOURS_IN_DAY * MINUTES_IN_HOUR));
    const newHours = Math.floor(newTotalMinutes % (HOURS_IN_DAY * MINUTES_IN_HOUR) / MINUTES_IN_HOUR);
    const newMinutes = newTotalMinutes % MINUTES_IN_HOUR;

    return {
        ...spin,
        time: {
            days: String(newDays),
            hours: String(newHours),
            minutes: String(newMinutes)
        }
    };
};
