import React, {Component} from 'react';

export default class EmptyOffers extends Component {
    render() {
        return <div className="offer offer--empty">
            <p className="subtitle">{this.props.subtitle}</p>
            <p className="description">{this.props.description}</p>
        </div>;
    }
}
