import React, {Component} from 'react';
import TimeToStartTimer from 'app/utils/TimeToStartTimer';
import TakePartButton from 'app/components/compendium/take_part_button';
import {i18n_t} from 'app/i18n';
import {fixed2} from 'app/utils/fixed';
import {userSignedIn} from 'app/utils';
import {translated} from 'app/utils/translations_helper';

const NO_NEXT_POINTS_VALUE = 0;
const INITIAL_MONEY_TO_THE_NEXT_POINT = 5;

export interface FooterPropsInterface{
    opened?: boolean,
    isLeaderboardAvailable: boolean,
    money_to_next_point: number,
    max_points: number,
    start_date: number,
    finished: boolean,
    predictions_total: number,
    reset_points_at: number,
    currentTimeStore: {
        t: number;
    };
}

interface RenderForSignedInInterface {
    isLeaderboardAvailable: boolean,
    max_points: number,
    money_to_next_point: number,
    reset_points_at:number
}

export interface HeaderPropsInterface {
    display_name: string,
    display_name_translations: {
        ru: string,
        en: string
    }
}

class CompendiumBody extends Component {
    static Footer(props: FooterPropsInterface): React.ReactNode {
        return <div className="compendium-aside__footer">
            {CompendiumBody.renderFooterContent(props)}
        </div>;
    }

    static Header(props: HeaderPropsInterface): React.ReactNode {
        return <div className="aside-item-heading__title">
            <h3>{translated(props, 'display_name', 'display_name_translations') || i18n_t('compendium.title')}</h3>
        </div>;
    }

    private static renderRemainingPoints = (
        max_points: number,
        money_to_next_point: number
    ): React.ReactNode => <div className="compendium-aside__footer-item">
        <p>{i18n_t('compendium.remaining_until_the_next_point')}:</p>
        <span>${fixed2(max_points ? NO_NEXT_POINTS_VALUE : money_to_next_point || INITIAL_MONEY_TO_THE_NEXT_POINT)}</span>
    </div>;

    private static renderResetPointsTimer = (reset_points_at: number): React.ReactNode | null => reset_points_at !== 0 &&
        <div className="compendium-aside__footer-item">
            <p>{i18n_t('compendium.points_cost_reset_in')}:</p>
            <TimeToStartTimer
                time_to_start={reset_points_at}
                daysComponentName="with_zero_days"
                textClass="compendium-aside__item-timer"
                timerClass="compendium-aside__item-time-left"
            />
        </div>;

    private static renderWaitingResults = (predictions_total: number): React.ReactNode | null => predictions_total > 0 &&
            <p className="compendium-aside__footer-results">
                {i18n_t('waiting_for_results_on_the_questions', {count: predictions_total})}
            </p>;

    private static renderForNotSignedIn = (isLeaderboardAvailable: boolean): React.ReactNode => <>
        {isLeaderboardAvailable
            ? <span>{i18n_t('the_champions_are_almost_determined')}</span>
            : <span>{i18n_t('compendium.has_started')}</span>}
        <TakePartButton className="btn compendium-aside__footer-btn" isRenderable />
    </>;

    private static renderForSignedIn = (
        {isLeaderboardAvailable,
            max_points,
            money_to_next_point,
            reset_points_at}:RenderForSignedInInterface
    ): React.ReactNode => <>
        {isLeaderboardAvailable
            ? <div className="compendium-aside__footer-item">
                <p>{i18n_t('the_champions_are_almost_determined')}</p>
            </div>
            : <>
                {CompendiumBody.renderRemainingPoints(max_points, money_to_next_point)}
                {CompendiumBody.renderResetPointsTimer(reset_points_at)}
            </>}
    </>;

    private static renderContentBasedOnSignedIn = (
        {isLeaderboardAvailable,
            max_points,
            money_to_next_point,
            reset_points_at}:RenderForSignedInInterface
    ): React.ReactNode => userSignedIn()
        ? CompendiumBody.renderForSignedIn({isLeaderboardAvailable, max_points, money_to_next_point, reset_points_at})
        : CompendiumBody.renderForNotSignedIn(isLeaderboardAvailable);

    private static renderFooterContent = (props: FooterPropsInterface): React.ReactNode => {
        const isUserSignedIn = userSignedIn();
        const {start_date,
            currentTimeStore,
            max_points,
            money_to_next_point,
            reset_points_at,
            predictions_total,
            isLeaderboardAvailable,
            finished,
            opened} = props;

        if (start_date > currentTimeStore.t) {
            return <div className="compendium-aside__footer-content">
                <span>{i18n_t('start_in')}:</span>
                <TimeToStartTimer
                    time_to_start={start_date}
                    daysComponentName="with_zero_days"
                    textClass="compendium-aside__item-timer"
                    timerClass="compendium-aside__item-time-left"
                />
            </div>;
        }

        if (start_date < currentTimeStore.t && !isLeaderboardAvailable && !finished) {
            return <div className="compendium-aside__footer-content compendium-aside__footer-content_started">
                {CompendiumBody.renderContentBasedOnSignedIn({isLeaderboardAvailable, max_points, money_to_next_point, reset_points_at})}
            </div>;
        }

        if (isLeaderboardAvailable && !finished) {
            const footerClasses = `compendium-aside__footer-content compendium-aside__footer-content_ending 
                ${isUserSignedIn && opened ? 'compendium-aside__footer-content_table' : ''}
                ${isUserSignedIn ? 'compendium-aside__footer-content_no-pb' : ''}`;

            return <>
                <div className={footerClasses}>
                    {CompendiumBody.renderContentBasedOnSignedIn({isLeaderboardAvailable, max_points, money_to_next_point, reset_points_at})}
                </div>
                {CompendiumBody.renderWaitingResults(predictions_total)}
            </>;
        }

        return <div className={`compendium-aside__footer-content
            compendium-aside__footer-content_finished 
            ${isLeaderboardAvailable && opened ? 'compendium-aside__footer-content_table' : ''}`}>
            <span>{i18n_t('compendium.has_finished')}</span>
        </div>;
    };
}

export default CompendiumBody;
