import React, {ReactNode} from 'react';
import PokerPlace from 'app/components/poker/poker_players/poker_places/PokerPlace';
import {inject, observer} from 'mobx-react';
import {PokerController} from 'app/controllers/PokerController';
import {PokerStore} from 'app/store/PokerStore';
import PlayerInterface from 'app/interfaces/poker/PlayerInterface';

interface Props {
    players?: PlayerInterface[]
    max_players: number
    count_players: number
    dealer_place: number
    action_index: number
    pokerStore?: PokerStore
    controller?: PokerController
}

@inject('pokerStore', 'controller')
@observer
export default class PokerPlayers extends React.Component<Props> {
    sitIn = (position: number): void => {
        this.props.controller.sitIn(this.props.pokerStore.currentTable.table_id, position);
    };

    renderAvailablePlaces = (): ReactNode => {
        const {max_players, players} = this.props;
        const positions = players?.map(item => item.position + 1) || [];
        const availablePlaces = Array.from({length: max_players}, (_, i) => i + 1).filter(num => !positions.includes(num));

        return availablePlaces.map((p, i) => <PokerPlace key={`f${i}`}
            onSitIn={this.sitIn}
            position={p}
            isBigBlind={false}
        />);
    };

    render(): ReactNode {
        const {players, dealer_place, action_index} = this.props;
        const {player} = this.props.pokerStore;

        return <>
            {players && players.map((p, i) => <PokerPlace
                key={`p${i}`}
                position={p.position + 1}
                active={p.position === action_index}
                player={p.name === player?.name ? player : p}
                isBigBlind={dealer_place === i}
                satOut={p.sat_out}
            />)}
            {!player && this.renderAvailablePlaces()}
        </>;
    }
}
