import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import EmptyOffers from 'app/components/my_profile/trades/empty_offers.jsx';

export default class FreespinsContentDescription extends PureComponent {
    render(): ReactNode {
        return <div className="exchange">
            <EmptyOffers
                subtitle={I18n.t('freespins_have_no_title')}
                description={I18n.t('freespins_have_no_description')}
            />
        </div>;
    }
}
