import React, {Component} from 'react';
import I18n from 'app/i18n';
import {translatedName} from 'app/utils/translations_helper';
import {SimpleArtifactImage} from 'app/components/my_profile/artifacts/image';
import {inject, observer} from 'mobx-react';
import BuyButton from 'app/components/shop/lootboxes/buy_button';
import {isGuest, scrollToTop} from 'app/utils';
import LootboxItem from './lootbox_item';
import {lootboxUse} from 'app/utils/lootbox_open/lootbox_use';
import {openPopupClickable} from 'app/components/my_profile/hero/clickable';
import NotificationStore from 'app/store/notifications';
import {OPEN_LOOTBOX_UPDATE_TIMEOUT, DELAY_BEFORE_OPENING_LOOTBOX} from 'app/store/ArtifactStore';
import {toastError} from 'app/components/toasts/liteToast';
import {currencyItem} from 'app/utils/ShopItemPriceFormat';
import SmartFullPageLoader from 'app/components/reusable/smart_loaders/SmartFullPageLoader';
import withRouter from 'app/utils/navigate/customWithRouter';
import GoBackButton from 'app/components/reusable/GoBackButton';
import {updateInfo} from 'app/common';


class LootboxDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idTimerShowPrize: null,
            loading: true,
            lootboxOpeningProcess: false
        };
        this.loadShopLootbox = this.loadShopLootbox.bind(this);
    }

    componentDidMount() {
        this.loadShopLootbox();
        scrollToTop();
        if (this.props.shopLootboxStore.openNowLootbox) {
            setTimeout(this.open, DELAY_BEFORE_OPENING_LOOTBOX);
            this.props.shopLootboxStore.updateOpenNowStatus(false);
            this.props.shopLootboxStore.updateBuyAndOpenNowStatus(false);
        }
    }

    componentWillUnmount() {
        const {idTimerShowPrize, lootboxOpeningProcess} = this.state;
        this.props.shopLootboxStore.updateLootbox({lootbox: null});
        this.props.shopLootboxStore.updateAnimationStatus(true);
        this.props.shopLootboxStore.updateOpenNowStatus(false);
        if (idTimerShowPrize) {
            clearTimeout(idTimerShowPrize);
        }
        if (lootboxOpeningProcess) {
            NotificationStore.add({
                type: 'leave_lootbox_page'
            });
        }
    }

    componentDidUpdate(props) {
        if (this.props.params.name !== props.params.name) {
            this.loadShopLootbox();
        }
        if (this.props.shopLootboxStore.openNowLootbox) {
            setTimeout(this.open, DELAY_BEFORE_OPENING_LOOTBOX);
            this.props.shopLootboxStore.updateOpenNowStatus(false);
            this.props.shopLootboxStore.updateBuyAndOpenNowStatus(false);
        }
    }

    get currency() {
        return new URLSearchParams(location.search).get('currency');
    }

    async loadShopLootbox() {
        const {shopLootboxStore, params: {name}} = this.props;
        await shopLootboxStore.updateLootboxBySlug(name, this.currency);
        this.setState({loading: false});
    }

    afterBuy = () => {
        this.loadShopLootbox();
        setTimeout(this.open, DELAY_BEFORE_OPENING_LOOTBOX);
    };

    loaderStart = () => {
        this.setState({lootboxOpeningProcess: true});
        setTimeout(() => {
            this.setState({lootboxOpeningProcess: false});
            updateInfo();
        }, OPEN_LOOTBOX_UPDATE_TIMEOUT);
    };

    open = () => {
        const {lootbox: {lootbox_to_open, user_lootbox_count}} = this.props.shopLootboxStore;
        const {lootboxOpeningProcess} = this.state;

        if (lootboxOpeningProcess || user_lootbox_count === 0) {
            return;
        }
        if (lootbox_to_open) {
            this.loaderStart();
            lootboxUse(lootbox_to_open, {
                doneOpenHandler: this.doneOpenHandler,
                show_loading: false,
                timeStart: Date.now()
            });
        } else {
            toastError(I18n.t('server_error_try_later'));
        }
    };

    doneOpenHandler = (artifact, response, params) => {
        const {timeStart} = params;
        const {
            extra_data,
            steam_skin,
            steam_trade,
            win_artifact
        } = response;
        this.loadShopLootbox();
        this.setState({
            idTimerShowPrize: setTimeout(
                () => openPopupClickable(artifact, true, {
                    extra_data,
                    steam_skin,
                    steam_trade,
                    win_artifact
                }),
                timeStart + OPEN_LOOTBOX_UPDATE_TIMEOUT - Date.now()
            )
        });
    };

    get showButtonOpenFromInventory() {
        return !isGuest() && !this.state.lootboxOpeningProcess;
    }

    render() {
        const {lootbox} = this.props.shopLootboxStore;
        const {lootboxOpeningProcess} = this.state;

        if (this.state.loading || !lootbox) {
            return <SmartFullPageLoader/>;
        }

        const {recipe, user_lootbox_count} = lootbox;
        return <div className="market-details">
            <div className="market-details--info">
                <div className="market-details--info--content">
                    <div className="market-details--top with--border">
                        <GoBackButton/>
                        <h1 className="market-details--info--desc-name">{translatedName(recipe)}</h1>
                    </div>
                    <div className="market-details--info--description">
                        <div className="market-details--info--img-wrap">
                            <div className="market-details--info--img">
                                <div className="market-details--info--inner--img">
                                    <SimpleArtifactImage artifact={recipe}/>
                                    <div className={`market-details--number-boxes ${
                                        user_lootbox_count && !lootboxOpeningProcess ? '' : 'hidden'}`}>
                                        <svg className="icon">
                                            <use xlinkHref="#svg-number-boxes"/>
                                        </svg>
                                        <span className="number-value">{user_lootbox_count}</span>
                                    </div>
                                    <div
                                        className={`market-details--loader ${
                                           lootboxOpeningProcess ? 'active' : ''
                                        }`}
                                    >
                                        <div className="loader-line-box ">
                                            <span className="loader-line">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="market-details--group-buttons">
                                {!isGuest() && <BuyButton
                                    {...lootbox}
                                    currency={currencyItem(this.currency)}
                                    lootboxOpeningProcess={lootboxOpeningProcess}
                                    customText={I18n.t('buy')}
                                    buyNow={this.props.shopLootboxStore.buyAndOpenNowLootbox}
                                    afterBuy={this.afterBuy}
                                    show_loading={false}
                                />}
                                {this.showButtonOpenFromInventory && <button
                                    className={`button-adaptive ${
                                        user_lootbox_count
                                            ? 'btn-open'
                                            : 'button-adaptive_disable'
                                    }`}
                                    disabled={!user_lootbox_count}
                                    onClick={this.open}
                                >
                                    {I18n.t('open')}
                                </button>}
                            </div>
                        </div>
                        <div className="market-details--info--desc">
                            <div className="market-details--info--desc-title gray-title">
                                <span className="icon-title">
                                    <svg>
                                        <use xlinkHref="#svg-gold-cup"/>
                                    </svg>
                                </span>
                                {I18n.t('you_may_win')}
                            </div>
                            <div className="market-details--info--desc-text">
                                <LootboxItem
                                    {...recipe}
                                    lootboxOpeningProcess={lootboxOpeningProcess}
                                    animationOpenEnd={this.props.shopLootboxStore.animationOpenEnd}
                                    updateAnimationStatus={this.props.shopLootboxStore.updateAnimationStatus}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default withRouter(inject('shopLootboxStore')(observer(LootboxDetails)));
