import {inject} from 'mobx-react';
import {observer} from 'mobx-react-lite';
import React, {ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import {ModalsStore} from 'app/store/ModalsStore';

interface PopupDialogInterface {
    modalsStore?: ModalsStore
    id: string
    setFocus?: boolean
    onClose?(): void
    hideCloseButton?: boolean
    fullscreen?: boolean
    children?: ReactNode;
}

const MAX_Z_INDEX = 999999999;

export const PopupDialog = inject('modalsStore')(observer((props: PopupDialogInterface) => {
    const {modalsStore} = props;
    const [zIndex] = useState(MAX_Z_INDEX + 1);
    const divRef = useRef(null);
    const modalStoreParams = modalsStore.getById(props.id);
    const callbackHandleCloseButton = useCallback(e => handleCloseButton(e), [props.id]);

    useEffect(() => {
        if (!modalsStore.isModalOpen(props.id) || !props.setFocus) {
            return;
        }

        const currentRef = divRef.current;
        const input = currentRef.querySelector('input:first-of-type');
        const button = currentRef.querySelector('button:first-of-type');

        if (input) {
            input.focus();
        } else if (button) {
            button.focus();
        }
    }, [props.setFocus, modalsStore.isModalOpen(props.id)]);

    const handleCloseButton = e => {
        e && e.preventDefault();
        props.onClose && props.onClose();
        modalsStore.hideModal(props.id);
    };

    return (
        <div ref={divRef} id={props.id} className={`popup ${modalStoreParams.visible ? 'fade-in' : 'fade-out'}`} style={{zIndex}}>
            <div className="popup__inner">
                <div className={`${props.fullscreen ? 'popup__full-wrap' : 'popup__wrap'}`}>
                    {props.hideCloseButton
                        ? <></>
                        : <a
                            href="#"
                            className="popup__close effect-no-tap"
                            id="popup-close"
                            onClick={callbackHandleCloseButton}
                        >
                            <svg height="21px" width="21px">
                                <use xlinkHref="#svg-close" />
                            </svg>
                        </a>
                    }
                    {props.children}
                </div>
            </div>
        </div>
    );
}));
