import React, {useState, useRef, useEffect} from 'react';

interface Props {
    text: string
}

const ScatterFAQ = (props: Props): React.ReactElement => {
    const [active, setActive] = useState(false);

    const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setActive(prevActive => !prevActive);
    };

    const modalRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (e: MouseEvent) => {
        e.stopPropagation();
        if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
            setActive(false);
        }
    };
    useEffect(() => {
        if (active) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [active]);

    return <div className={`scatter-faq ${active ? 'active' : ''}`}>
        <button onClick={e => onClick(e)}>
            <svg height="20px" width="20px">
                <use xlinkHref="#svg-question"/>
            </svg>

        </button>
        <div className="scatter-faq-description" ref={modalRef}>
            <div className="scatter-faq-description__inner">
                <p>{props.text}</p>
            </div>
        </div>
    </div>;
};

export default ScatterFAQ;
