import {artifactStore} from 'app/store/global';
import {modalConfirm} from 'app/utils/modals/popups';
import {firstElementResponse} from 'app/utils/lootbox_open/first_element_response';
import InventoryItemInterface from 'app/interfaces/InventoryItemInterface';

interface Params {
    doneOpenHandler: (item: InventoryItemInterface, response: Map<string, string>, params: Params) => void,
    timeStart: number
    show_loading?: boolean
}

export const lootboxUse = (artifact: InventoryItemInterface, params: Params, continue_equipping = false): void => {
    const {public_view} = artifact;
    const {doneOpenHandler, show_loading} = params;

    if (public_view) {
        return;
    }
    artifactStore
        .use(artifact, continue_equipping, show_loading)
        .fail(response => {
            modalConfirm(firstElementResponse(response),
                () => lootboxUse(artifact, params, true));
        })
        .done(response => doneOpenHandler(artifact, response, params));
};
