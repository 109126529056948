import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import {scrollToTop} from 'app/utils';

const MOBILE_WIDTH = 480;
const MOBILE_MAX_VISIBLE = 6;
const MAX_VISIBLE = 9;

export default class Pagination extends PureComponent {
    static FIRST_PAGE = 1;

    static COUNT_PAGES = window.innerWidth <= MOBILE_WIDTH ? MOBILE_MAX_VISIBLE : MAX_VISIBLE;

    constructor(props) {
        super(props);
        this.changePage = this.changePage.bind(this);
    }

    changePage(params) {
        const {searchValue, switchPage} = this.props;

        if (searchValue) {
            switchPage(params, searchValue);
        } else {
            switchPage(params);
        }
        scrollToTop();
        this.setState({current_page: params.page});
    }

    needThePaginate() {
        return this.props.total_pages > 1;
    }

    nextPage = () => {
        const {current_page, total_pages} = this.props;

        if (current_page < total_pages) {
            this.changePage(this.queryParams(current_page + 1));
        }
    };

    previousPage = () => {
        const {current_page} = this.props;

        if (current_page > 1) {
            this.changePage(this.queryParams(current_page - 1));
        }
    };

    enumeration() {
        const {current_page} = this.props;
        let maxVisible = this.constructor.COUNT_PAGES;
        const max = this.props.total_pages;
        let skip = 0;
        if (maxVisible > max) {
            maxVisible = max;
        } else if (current_page > maxVisible - 1 && current_page < max) {
            skip = current_page - maxVisible + 1;
        } else if (current_page === max) {
            skip = current_page - maxVisible;
        }

        const page = Array(...new Array(maxVisible)).map((v, i) => skip + i + 1);

        const ELEMENT_NOT_FOUND = -1;

        if (page.indexOf(this.constructor.FIRST_PAGE) === ELEMENT_NOT_FOUND) {
            page.unshift(this.constructor.FIRST_PAGE, '...');
        }
        if (page.indexOf(max) === ELEMENT_NOT_FOUND) {
            page.push('...', max);
        }
        return page;
    }

    queryParams(page) {
        page = page > this.props.total_pages ? this.props.total_pages : page;
        return {
            ...this.props.query || {},
            page: page || 1
        };
    }

    render() {
        if (!this.needThePaginate()) {
            return null;
        }
        return <ul className="smart-pagination">
            <li className={this.props.current_page === 1 ? 'disabled' : null}>
                <Link to={{
                    ...location,
                    query: this.queryParams(this.props.current_page - 1)
                }}
                onClick={this.previousPage}
                className="button-pagination prev">
                    <svg className="svg-default">
                        <use xlinkHref="#pag-arr-left"/>
                    </svg>
                </Link>
            </li>
            {this.enumeration().map((page, index) => typeof page === 'number'
                ? <li className={this.props.current_page === page ? 'active' : null} key={index}>
                    <Link to={{
                        ...location,
                        query: this.queryParams(page)
                    }}
                    onClick={this.changePage.bind(this, this.queryParams(page))}
                    className="button-adaptive button-adaptive_instyle">{page}
                    </Link>
                </li>
                : <li key={index} className="disabled"><Link>{page}</Link></li>)}
            <li className={this.props.current_page === this.props.total_pages ? 'disabled' : null}>
                <Link to={{
                    ...location,
                    query: this.queryParams(this.props.current_page + 1)
                }}
                onClick={this.nextPage}
                className="button-pagination next">
                    <svg className="svg-default">
                        <use xlinkHref="#pag-arr-right"/>
                    </svg>
                </Link>
            </li>
        </ul>;
    }
}
