import React, {PureComponent} from 'react';
import SwiperCore from 'swiper';
import {Swiper} from 'swiper/swiper-react';
import {Navigation, Pagination} from 'swiper/modules';
import {isDesktop} from 'app/utils';

const desktopGroup = 3;
const mobileGroup = 1;

const swiperParams = {
    allowTouchMove: true,
    freeMode: {
        momentum: false,
        momentumBounce: false
    },
    observeParents: true,
    observer: true,
    slidesPerGroup: isDesktop() ? desktopGroup : mobileGroup,
    speed: 400,
    touchRatio: 0.65,
    touchReleaseOnEdges: false,
    touchStartForcePreventDefault: true
};

interface FreespinsContentContainerProps {
    isSwiper: boolean
    onCheckReached?: (swiper: SwiperCore) => void
    children?: React.ReactNode
    nameForControls: string
}

export default class FreespinsContentContainer extends PureComponent<FreespinsContentContainerProps> {
    render(): React.ReactNode {
        const {children, onCheckReached, nameForControls} = this.props;

        return this.props.isSwiper
            ? <>
                <Swiper className="slider-container-swiper" {...swiperParams}
                    slidesPerView="auto"
                    freeMode
                    preventInteractionOnTransition
                    preventClicksPropagation
                    resistance={false}
                    watchSlidesProgress
                    onTouchEnd={onCheckReached}
                    onAfterInit={onCheckReached}
                    modules={[Pagination, Navigation]}
                    pagination={{
                        clickable: true,
                        el: `.pagination-${nameForControls}`
                    }}
                    navigation={{
                        nextEl: `.button-${nameForControls}-next`,
                        prevEl: `.button-${nameForControls}-prev`
                    }}>{children}</Swiper>
                <div className="slider-pagination-control">
                    <button className={`swiper-button-slider-prev button-${nameForControls}-prev`}>
                        <svg className="svg-default">
                            <use xlinkHref="#svg-swiper-arrow"/>
                        </svg>
                    </button>
                    <div className={`swiper-pagination-group pagination-${nameForControls}`}/>
                    <button className={`swiper-button-slider-next button-${nameForControls}-next`}>
                        <svg className="svg-default">
                            <use xlinkHref="#svg-swiper-arrow"/>
                        </svg>
                    </button>
                </div>
            </>
            : <div className="slider-container-free">{children}</div>;
    }
}
