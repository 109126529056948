import $ from 'jquery';
import React, {Component, MouseEvent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {scrollToTop, time, userSignedIn} from 'app/utils';
import {CHAT_BAN, CHAT_HERO, CHAT_MUTE, CHAT_SEND, CHAT_SWAP, CHAT_TRADE, CHAT_TRASH_BIN} from 'app/consts';
import {showSendForm, showSwapForm} from '../promo_action/gift';
import {goToRoute} from 'app/utils/link_helper';
import {showBetForm} from 'app/components/table';
import {hideChatActions, rowColor} from 'app/components/chat/chat_utils';
import {showUserMutePopup} from 'app/components/chat/UserMutePopup';
import {showUserBanPopup} from 'app/components/chat/user_ban_popup';
import {goToLinkMarketplace, goToShopLootbox, goToCasino} from 'app/utils/app_links';
import {startCasinoGame} from 'app/services/CasinoServices';
import {inject, observer} from 'mobx-react';
import {MessageInterface} from 'app/interfaces/stores/chat/MessageInterface';
import {ChatStore} from 'app/store/ChatStore';
import {promoActionStore} from 'app/store/global';

const ADMIN = 1;
const MODERATOR = 2;
const USER = 11;
const MODERATORS_GROUPS = [
    ADMIN,
    MODERATOR,
    USER
];

const NOT_FOUND_INDEX = -1;

interface Props {
    chatStore?: ChatStore,
    giftsEnabled: boolean,
    insertUserName: (name: string) => void,
    message: MessageInterface
}

@inject('chatStore')
@observer
export default class ChatMessage extends Component<Props> {
    messageContent: React.RefObject<HTMLSpanElement> = React.createRef();

    componentDidMount(): void {
        this.clickOnBetFromChat($('.open-chat-bet', this.messageContent.current));
        this.clickOnLootboxFromChat($('.open-shop-lootboxes', this.messageContent.current));
        this.clickOnMarketplaceFromChat($('.open-shop-marketplace', this.messageContent.current));
        this.clickOnCasinoGameFromChat($('.open-casino-game', this.messageContent.current));
    }

    clickOnBetFromChat = (chatBet: HTMLFormElement): void => {
        chatBet.on('click', this.clickBetLinkHandler);
    };

    clickOnLootboxFromChat = (chatLootbox: HTMLFormElement): void => {
        chatLootbox.on('click', this.clickLootboxLinkHandler);
    };

    clickOnMarketplaceFromChat = (chatMarketplace: HTMLFormElement): void => {
        chatMarketplace.on('click', this.clickMarketplaceLinkHandler);
    };

    clickOnCasinoGameFromChat = (chatCasinoGame: HTMLFormElement): void => {
        chatCasinoGame.on('click', this.clickCasinoGameLinkHandler);
    };

    clickBetLinkHandler(): void {
        scrollToTop();
        const data = $(this).data();
        showBetForm({hash_id: data.hashId}, data.on, {openBetSlip: true});
    }

    clickLootboxLinkHandler(e: MouseEvent): void {
        e.preventDefault();
        goToShopLootbox($(this).data().slug);
        scrollToTop();
    }

    clickMarketplaceLinkHandler(e: MouseEvent): void {
        e.preventDefault();
        goToLinkMarketplace($(this).data());
        scrollToTop();
    }

    clickCasinoGameLinkHandler(e: MouseEvent): void {
        e.preventDefault();
        goToCasino();
        startCasinoGame($(this).data('id'));
        scrollToTop();
    }

    canMute = (): boolean => userSignedIn() &&
        window.settings.user.id !== this.props.message.uid &&
        this.props.message.uid !== 0 &&
        MODERATORS_GROUPS.indexOf(this.props.message.ug) === NOT_FOUND_INDEX &&
        MODERATORS_GROUPS.indexOf(window.settings.user.group) === NOT_FOUND_INDEX;

    canDelete = (): boolean => window.settings.user.id === this.props.message.uid ||
        MODERATORS_GROUPS.indexOf(window.settings.user.group) > NOT_FOUND_INDEX;

    canBan = (): boolean => userSignedIn() &&
        MODERATORS_GROUPS.indexOf(window.settings.user.group) > NOT_FOUND_INDEX &&
        MODERATORS_GROUPS.indexOf(this.props.message.ug) === NOT_FOUND_INDEX;

    hasActions = (): boolean => this.canDelete() || this.canMute() || this.canBan();

    canGift = (): boolean => this.props.giftsEnabled && (this.canMute() || this.canBan());

    isModeratorMessage = (): boolean => MODERATORS_GROUPS.indexOf(this.props.message.ug) !== NOT_FOUND_INDEX;

    publicInventory = (): void => {
        goToRoute(`/profile/${this.props.message.un}`);
        hideChatActions();
        scrollToTop();
    };

    offerTrade = (): void => {
        goToRoute(`/profile/${this.props.message.un}/trade`);
        hideChatActions();
        scrollToTop();
    };

    handleDeleteMessage = (e: MouseEvent): void => {
        e.preventDefault();
        this.props.chatStore.deleteMessage(this.props.message.id);
    };

    handleShowUserBanPopup = (e: MouseEvent): void => {
        e.preventDefault();
        const {uid, un} = this.props.message;
        showUserBanPopup(uid, un);
    };

    handleShowUserMutePopup = (e: MouseEvent): void => {
        e.preventDefault();
        const {uid, un} = this.props.message;
        showUserMutePopup(uid, un, this.props.chatStore);
    };

    handleInsertUserName = (e: MouseEvent): void => {
        e.preventDefault();
        this.props.insertUserName(this.props.message.un);
    };

    handleGiftShowForm = (e: MouseEvent): void => {
        e.preventDefault();
        showSendForm(this.props.message.un, promoActionStore.promoActions.PromoActionPresent.to_send);
    };

    handleGiftSwapForm = (e: MouseEvent): void => {
        e.preventDefault();
        showSwapForm(this.props.message.un);
    };

    render(): ReactNode {
        const {ca, un, ug, uid} = this.props.message;
        return <div className="chat__message">
            <span className="chat__message-info">
                <span className="chat__message-time">{time(ca)}</span>
                {this.hasActions() && <span className="chat__actions">
                    <span className="chat__actions-toggle">
                        <svg height="12px" width="10px">
                            <use xlinkHref="#svg-arr-down"/>
                        </svg>
                    </span>
                    <div className="chat__actions-list">
                        {this.canBan() && <div
                            className="chat__actions-item"
                            onClick={this.handleShowUserBanPopup}
                        >
                            <img src={CHAT_BAN} alt="B" title="Ban user"/>
                            <div className="chat__actions-item"><span>Ban</span></div>
                        </div>}
                        {this.canMute() && <div
                            className="chat__actions-item"
                            onClick={this.handleShowUserMutePopup}
                        >
                            <img src={CHAT_MUTE} alt="M" title={I18n.t('user.mute')}/>
                            <div className="chat__actions-item"><span>{I18n.t('user.mute')}</span></div>
                        </div>}
                        {this.canDelete() && <div
                            className="chat__actions-item"
                            onClick={this.handleDeleteMessage}
                        >
                            <img src={CHAT_TRASH_BIN} alt="D" title={I18n.t('user.delete')}/>
                            <div className="chat__actions-item"><span>{I18n.t('user.delete')}</span></div>
                        </div>}
                        {this.canGift() && <div
                            className="chat__actions-item"
                            onClick={this.handleGiftShowForm}
                        >
                            <img src={CHAT_SEND} alt="SGift" title={I18n.t('user.promo_action.send_gift')}/>
                            <div className="chat__actions-item">
                                <span>{I18n.t('user.promo_action.send_gift')}</span>
                            </div>
                        </div>}
                        {this.canGift() && <div
                            className="chat__actions-item"
                            onClick={this.handleGiftSwapForm}
                        >
                            <img src={CHAT_SWAP} alt="SWGift" title={I18n.t('user.promo_action.swap_gift')}/>
                            <div className="chat__actions-item">
                                <span>{I18n.t('user.promo_action.swap_gift')}</span>
                            </div>
                        </div>}
                        {!this.isModeratorMessage() && <div
                            className="chat__actions-item"
                            onClick={this.publicInventory}
                        >
                            <img src={CHAT_HERO} alt="Inv" title={I18n.t('inventory')}/>
                            <div className="chat__actions-item"><span>{I18n.t('inventory')}</span></div>
                        </div>}
                        {!this.isModeratorMessage() && <div className="chat__actions-item" onClick={this.offerTrade}>
                            <img src={CHAT_TRADE} alt="Trade" title={I18n.t('offer_trade')}/>
                            <div className="chat__actions-item"><span>{I18n.t('offer_trade')}</span></div>
                        </div>}
                    </div>
                </span>}

                <a href="#" className={`chat__message-nickname ${rowColor(ug, uid)}`}
                    style={{color: this.props.message.name_color}}
                    onClick={this.handleInsertUserName}>{un}</a>
            </span>
            <span className="chat__message-text" ref={this.messageContent}
                dangerouslySetInnerHTML={{__html: this.props.message.m}}/>
        </div>;
    }
}
