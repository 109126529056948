import React, {Component} from 'react';
import SellNowSteamSkin from 'app/components/common/SellNowSteamSkin';
import {goToShopLootbox} from 'app/utils/app_links';
import {hideLastPopup, showPopup} from 'app/utils/modals/ModalsService';
import {scrollToTop} from 'app/utils';
import Prize from './popup/prize';
import I18n from 'app/i18n';
import SendArtifactPopup from './send_artifact_popup';
import {lootboxToChat} from 'app/utils/share_to_chat';
import PrizeBackground from './popup/prize_background';
import BuyButton from 'app/components/shop/lootboxes/buy_button';
import {inject, observer} from 'mobx-react';
import SellArtifactPopup from './sell_artifact_popup';
import ActionButton from './action_button';
import {currencyItem, isUSD} from 'app/utils/ShopItemPriceFormat';
import {prizeName} from 'app/components/my_profile/artifacts/popup/PrizeName';
import {userStore} from 'app/store/global';

@inject('shopLootboxStore')
@observer
export default class OpenedLootboxPopupContent extends Component {
    state = {
        currency: ''
    };

    componentDidMount() {
        this.updateCurrencyByLocation();
    }

    updateLootbox = () => {
        const {shopLootboxStore, commonProperties: {slug}} = this.props;
        const {currency} = this.state;
        shopLootboxStore.updateLootboxBySlug(slug, currency);
    };

    buyMore = lootboxAndItsId => () => {
        const {commonProperties, shopLootboxStore} = this.props;
        const {currency} = this.state;
        hideLastPopup('inventory-popup');
        this.updateLootbox();
        if (lootboxAndItsId) {
            shopLootboxStore.updateOpenNowStatus(true);
        } else {
            shopLootboxStore.updateBuyAndOpenNowStatus(true);
        }
        goToShopLootbox(commonProperties.slug, !isUSD(currency));
        scrollToTop();
    };

    shareToChat = () => {
        const {commonProperties: {id}} = this.props;
        lootboxToChat(id);
        hideLastPopup();
    };

    get prize() {
        const {commonProperties: {extra_data}} = this.props;
        const [prize] = extra_data;
        return prize;
    }

    get artifact() {
        const {commonProperties: {win_artifact}} = this.props;
        return win_artifact;
    }

    send = () => {
        showPopup('inventory-popup', props => <SendArtifactPopup artifact={this.artifact} popupId={props.popupId}/>);
    };

    updateCurrencyByLocation = () => {
        const urlParams = new URLSearchParams(window.location.search);
        this.setState({currency: currencyItem(urlParams.get('currency'))});
    };

    sellArtifact = () => {
        showPopup(
            'sell-inventory',
            props => <SellArtifactPopup artifact={this.artifact} popupId={props.popupId}/>,
            ['inventory-popup']
        );
    };

    get showButtonSell() {
        const {restrict_artifact} = userStore.user;
        return this.artifact &&
            this.artifact.salable &&
            !restrict_artifact;
    }

    render() {
        const {commonProperties: {steam_skin, steam_trade}, shopLootboxStore} = this.props;
        const {currency} = this.state;
        const lootboxAndItsId = shopLootboxStore.lootbox && shopLootboxStore.lootbox.id;
        return <div className="prize-won">
            <div className="prize-won-title">
                <h2>{I18n.t('congratulations_you_win')}</h2>
            </div>
            <div className="prize-won-name">
                <span className="prize-won-name__icon">
                    <svg>
                        <use xlinkHref="#svg-gold-cup"/>
                    </svg>
                </span>
                <span className="prize-won-name__name">
                    {prizeName(this.prize)}
                </span>
            </div>
            <div className="prize-won-image-wrapper">
                <div className="prize-won-image">
                    <div className="prize-won-adaptive-image">
                        <Prize loot={this.prize}/>
                    </div>
                    <PrizeBackground {...this.prize}/>
                </div>
            </div>
            <div className="prize-won-buttons">
                <div className="row">
                    { lootboxAndItsId
                        ? <BuyButton
                            {...shopLootboxStore.lootbox}
                            currency={currency}
                            customText={I18n.t('buy_more')}
                            afterBuy={this.buyMore(lootboxAndItsId)}
                        />
                        : <button
                            className="button-adaptive button-adaptive_uppercase"
                            onClick={this.buyMore(lootboxAndItsId)}
                        >
                            {I18n.t('open_more')}
                        </button>}
                </div>
                <div className="row row_group-button">
                    {steam_skin && <SellNowSteamSkin
                        popupId="inventory-popup"
                        steam_skin={steam_skin}
                        steam_trade={steam_trade}
                        btnRemoveClassBtn
                        btnClass="button-adaptive button-adaptive_full-thin"
                    />}
                    {this.showButtonSell && <ActionButton
                        {...this.artifact}
                        onClickHandler={this.sellArtifact}
                        btnText={I18n.t('sell')}
                        btnRemoveClassBtn
                        btnClass="button-adaptive button-adaptive_full-thin"
                    />}
                    <button className="button-adaptive button-adaptive_full-thin-icon" onClick={this.shareToChat}>
                        <span className="button-icon">
                            <svg>
                                <use xlinkHref="#svg-arrow-link"/>
                            </svg>
                        </span>
                        <span className="button-name">{I18n.t('in_chat')}</span>
                    </button>
                </div>
            </div>
        </div>;
    }
}
