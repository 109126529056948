import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import FreespinsContentList from 'app/components/my_profile/freespins/FreespinsContentList';


export default class FreespinsContent extends PureComponent {
    render(): ReactNode {
        return <>
            <h1 className="title-h1">{I18n.t('freespins_available')}</h1>
            <div className="freespins-content">
                <FreespinsContentList/>
            </div>
        </>;
    }
}
