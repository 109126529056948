import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import I18n from 'app/i18n';
import FreespinInterface from 'app/interfaces/Freespinsinterface';
import {UserBonusesStore} from 'app/store/UserBonusesStore';
import {
    getActivateClickHandler,
    getCancelClickHandler,
    getDurationText
} from 'app/helpers/free_spin_helpers.tsx';

interface PropsInterface {
    spin: FreespinInterface;
    userBonusesStore?: UserBonusesStore;
}

@inject('userBonusesStore')
@observer
export default class FreespinsContentItem extends Component<PropsInterface> {
    render(): React.ReactNode {
        const {spin, userBonusesStore} = this.props;

        return (
            <div className="freespins-item">
                <div className="freespins-item__about">
                    <div className="freespins-provider">
                        <span className="freespins-item-title">{I18n.t('provider')}</span>
                        <span className="freespins-item-name" title={spin.provider}>
                            {spin.provider}
                        </span>
                    </div>
                    <span className="freespins-count">{spin.quantity}</span>
                </div>
                <div className="freespins-game">
                    <span className="freespins-item-title">{I18n.t('freespins_slot')}</span>
                    <span className="freespins-item-name" title={spin.game}>
                        {spin.game}
                    </span>
                </div>
                <div className="freespins-timer">
                    <span className="freespins-timer-title">{I18n.t('expiring_in')}</span>
                    <span className="freespins-timer-count">
                        {getDurationText(spin.time)}
                    </span>
                </div>
                <div className="freespins-buttons">
                    <button
                        className="button-adaptive button-adaptive_uppercase"
                        onClick={getActivateClickHandler(spin.game_id)}
                    >
                        {I18n.t('go_game')}
                    </button>
                    <button
                        className="button-adaptive button-adaptive_crystal"
                        onClick={getCancelClickHandler(spin, userBonusesStore!)}
                    >
                        {I18n.t('casino_free_spins.cancel')}
                    </button>
                </div>
            </div>
        );
    }
}
