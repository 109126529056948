import {inject, observer} from 'mobx-react';
import React, {ReactNode, RefObject} from 'react';
import {gameImage} from 'app/utils';
import Row from './index';
import AdvantageLine from './advantage_line';
import HandicapLine from './HandicapLine';
import {isRootLine} from 'app/utils/line/rootLine';
import {isHandicapLine} from 'app/lines/isHandicapLine';
import {ActiveCoef, createSerialRendering} from 'app/lines/createSerialRendering';
import MapWithNestedLines from 'app/components/table/row/MapWithNestedLines';
import {WHITE} from 'app/lines/line_helpers';
import {groupByType} from 'app/utils/nestedLinesGroupping/groupByType';
import {sortGroupedData} from 'app/utils/nestedLinesGroupping/sortGrouppedData';
import GroupContainer from './GroupContainer';
import I18n from 'app/i18n';
import LineInterface from 'app/interfaces/LineInterface';
import {TableStore} from 'app/store/TableStore';
import {UserStore} from 'app/store/UserStore';
import {GroupedData} from '../../../utils/nestedLinesGroupping/groupByType';
import {isAdvantageLine} from 'app/lines/isAdvantageLine';


interface MainLineProps {
    tableStore: TableStore;
    userStore: UserStore;
    showNestedBets?: boolean;
    bet: LineInterface;
    isNestedBet?: boolean;
    activeCoef?: ActiveCoef;
    coefOnClick?: number
    isExpress?: boolean;
    seo?: string;
}

const DEFAULT_HANDICAP = 'handicap_0';

@inject('tableStore', 'userStore')
@observer

export default class MainLine extends Row {
   container: RefObject<HTMLDivElement>;

   constructor(props: MainLineProps) {
       super(props);
       this.container = React.createRef();
       this.state = {
           loading: false,
           showModal: false,
           showNestedBets: Boolean(this.props.showNestedBets),
           widgetUrl: ''
       };
   }

   componentDidMount(): void {
       const {showNestedBets} = this.props;

       if (showNestedBets) {
           this.collapseRow(showNestedBets);
       }
   }

    renderTableExpand = (): ReactNode => <span className="table-expand table-expand--inner" />;

    renderGameImage(): ReactNode {
        const {isNestedBet, bet} = this.props;
        return isNestedBet ? null : gameImage(bet.filter);
    }

    nestedLineComponent = (line: LineInterface, props: MainLineProps): ReactNode => {
        if (line.color === WHITE && this.props.tableStore.results === false || line.hide && !line.live) {
            return null;
        }

        if (isAdvantageLine(line)) {
            return <AdvantageLine key={`advantage-line-${line.id}`} {...props} />;
        }
        if (isHandicapLine(line)) {
            return <HandicapLine key={`handicap-line-${line.id}`} {...props} />;
        }
        if (line.type_map === true && line.nested_bets_count > 1) {
            return <MapWithNestedLines key={`nested-line-${line.id}`} show={this.state.showNestedBets} {...props} />;
        }

        return <MainLine key={`nested-line-${line.id}`} {...props} />;
    };

    nestedLineWithoutNestedToNested = (nestedBets: LineInterface[]): boolean => {
        if (isRootLine(this.props.bet)) {
            return nestedBets.filter(line => line.nested_bets_count > 0).length === 0;
        }
        return false;
    };

    handleChildStateChange = (opened: boolean): void => {
        this.setState(prevState => {
            const shouldAddClass = opened;

            if (prevState.addClass !== shouldAddClass) {
                return {addClass: shouldAddClass};
            }

            return null;
        });
    };

    groupByTypeAndSort(): GroupedData {
        const groupedData = groupByType(this.nestedBets(), this.props.tableStore.results, this.props.userStore.user.site_lang);
        return sortGroupedData(groupedData);
    }

    renderNestedBets(): ReactNode {
        const {bet, activeCoef, coefOnClick, isExpress, seo, tableStore, userStore} = this.props;
        const {showNestedBets} = this.state;

        return this.nestedBets().map(nestedLine => {
            const gamerActiveCoef = activeCoef && activeCoef(nestedLine.id);
            const props = {
                activeCoef,
                bet: nestedLine,
                coefOnClick,
                color: nestedLine.color,
                gamerActiveCoef,
                isAdvantage: isAdvantageLine(nestedLine),
                isExpress,
                isNestedBet: true,
                nested: nestedLine.nested_bets_count <= 0,
                parentBet: bet,
                seo,
                serial: createSerialRendering(nestedLine, activeCoef),
                showNestedBets,
                tableStore,
                userStore,
                ut: nestedLine.ut
            };
            return this.nestedLineComponent(nestedLine, props);
        });
    }

    render(): ReactNode {
        const nestedBets = this.nestedBets();

        const {showNestedBets} = this.state;
        const {
            bet,
            bet: {color, has_advantage, hash_id, nested_lines_count, bo_1},
            isNestedBet
        } = this.props;

        const rowClass = `table-bets__${isNestedBet ? 'inner1-row' : 'main-row'}
        js-expand-row${showNestedBets ? ' main-row-openned' : ''} has-already-bet`;
        const colorClass = `table-bets__${isNestedBet ? 'inner-row' : 'main-row'}-holder
        holder--color${color}${has_advantage ? ' advantage' : ''}`;

        const groupedData = this.groupByTypeAndSort();

        return (
            <div id={`line-row-${hash_id}`} className={rowClass}>
                {isNestedBet
                    ? <div className={colorClass}>
                        <div
                            className={`table-bets__content
                        ${isNestedBet ? 'nested' : ''} table-bets__border-bottom`}
                        >
                            {this.renderButtonPlus('table-expand--main', nested_lines_count, nested_lines_count >= 1)}
                            {this.renderBody(true)}
                        </div>
                    </div>
                    : <div className={colorClass}>
                        {this.renderButtonPlus('table-expand--main', nested_lines_count, nested_lines_count >= 1)}
                        {this.renderBody()}
                    </div>
                }

                {nestedBets.length > 0 &&
                    <div
                        className={`table-bets__main-row-container js-main-container
                ${
            this.nestedLineWithoutNestedToNested(nestedBets) ? 'first-line' : 'no_first_nested'
            }`}
                        ref={this.container}
                    >
                        {bo_1
                            ? groupedData &&
                              Object.entries(groupedData).map(([key, items], index) => items.length >= 1
                                  ? <GroupContainer
                                      key={`nested-line-${index}`}
                                      nestedLines={items}
                                      showNestedBets={showNestedBets}
                                      bet={bet}
                                      name={key === 'null' ? I18n.t(DEFAULT_HANDICAP) : key}
                                      handleChildStateChange={this.handleChildStateChange}
                                      {...this.props}
                                  />
                                  : null)
                            : showNestedBets && this.renderNestedBets()
                        }
                    </div>
                }
            </div>
        );
    }
}
