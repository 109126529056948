import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {UserBonusesStore} from 'app/store/UserBonusesStore';
import {SwiperSlide} from 'swiper/swiper-react';
import FreespinsContentContainer from 'app/components/my_profile/freespins/FreespinsContentContainer';
import FreespinsContentItem from 'app/components/my_profile/freespins/FreespinsContentItem';
import FreespinsContentDescription from 'app/components/my_profile/freespins/FreespinsContentDescription';
import FreespinInterface from 'app/interfaces/Freespinsinterface';
import {getWidth} from 'app/utils/getWidth';
import {isDesktop} from 'app/utils';

import {updateSpinTime} from 'app/helpers/free_spin_helpers';

const MILLISECONDS_IN_MINUTE = 60000;
const STUBS_COUNT = 5;

interface PropsInterface {
  userBonusesStore?: UserBonusesStore;
}

interface StateInterface {
  renderSlider: boolean;
  menuGameLength: number;
  loading: boolean;
  spins: FreespinInterface[];
}

@inject('userBonusesStore')
@observer

export default class FreespinsContentList extends Component<PropsInterface, StateInterface> {
    rootElement: React.RefObject<HTMLDivElement> = React.createRef();

    viewMenuSlideElement: React.RefObject<HTMLDivElement> = React.createRef();

    private intervalId: NodeJS.Timeout | null = null;

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: true,
            menuGameLength: 0,
            renderSlider: true,
            spins: []
        };
    }

    componentDidMount(): void {
        const {userBonusesStore} = this.props;

        this.updateSpins();
        this.setState(
            {loading: false, menuGameLength: userBonusesStore?.free_spins.length},
            this.setRenderSwiper
        );

        this.setState({menuGameLength: userBonusesStore?.free_spins.length}, () => {
            this.setRenderSwiper();
        });

        this.intervalId = setInterval(this.updateSpinsTime, MILLISECONDS_IN_MINUTE);

        window.addEventListener('resize', this.setRenderSwiper);
    }

    componentDidUpdate(prevProps: PropsInterface): void {
        const {userBonusesStore} = this.props;

        if (
            userBonusesStore?.free_spins !== prevProps.userBonusesStore?.free_spins ||
            userBonusesStore?.free_spins.length !== this.state.menuGameLength
        ) {
            this.updateSpins();
            this.setState(
                {menuGameLength: userBonusesStore?.free_spins.length || 0},
                this.setRenderSwiper
            );
        }
    }

    componentWillUnmount(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        window.removeEventListener('resize', this.setRenderSwiper);
    }

    updateSpins = (): void => {
        const {userBonusesStore} = this.props;
        this.setState({spins: userBonusesStore.free_spins as FreespinInterface[]});
    };

    updateSpinsTime = (): void => {
        const {spins} = this.state;

        if (spins.length === 0) {
            return;
        }

        const updatedSpins = spins.map(updateSpinTime);

        this.setState({spins: updatedSpins.filter(Boolean) as FreespinInterface[]});
    };

    setRenderSwiper = (): void => {
        const min_slider_count = 2;
        const {spins} = this.state;
        const viewMenu = this.viewMenuSlideElement.current?.clientWidth;
        const menuList = getWidth('.freespins .slider-container-free') ||
            getWidth('.freespins .slider-container-swiper .swiper-wrapper');

        this.setState({renderSlider: viewMenu < menuList || !isDesktop() && spins.length > min_slider_count});
    };

    renderGameItems = (count: number): React.ReactElement => <div className="slider-preloader">
        {Array.from({length: count}, (_, index) => <span key={index} className="slider-preloader-item"/>)}
    </div>;

    render(): React.ReactNode {
        const {spins, renderSlider, loading} = this.state;
        const empty = this.props.userBonusesStore.freeSpinsCount() === 0 || !loading && (!spins || spins.length === 0);

        return empty
            ? <FreespinsContentDescription/>
            : <div className="slider-main-container slider-freespins">
                <div className="slider-main-container-body" ref={this.viewMenuSlideElement}>
                    {spins && spins.length === 0 && loading
                        ? <>{this.renderGameItems(STUBS_COUNT)}</>
                        : <div className={`slider-container-group${renderSlider ? '' : ' free'}`}
                            ref={this.rootElement}>
                            <FreespinsContentContainer isSwiper={renderSlider} nameForControls="freespins1">
                                {spins.map(spin => <SwiperSlide key={`filter-${spin.id}`}>
                                    <FreespinsContentItem spin={spin}/>
                                </SwiperSlide>)}
                            </FreespinsContentContainer>
                        </div>}
                    {!renderSlider && <div className="slider-pagination-shadow"/>}
                </div>
            </div>;
    }
}
