import React, {PureComponent} from 'react';
import {getLinkPath} from 'app/utils/link_helper';
import {updateInfo} from 'app/common';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {enableEscPopupClose, modalConfirm, preventEscPopupClose} from 'app/utils/modals/popups';
import I18n from 'app/i18n';
import Post from 'app/utils/Post';
import ShopItemGift from 'app/components/promo_action/gift/ShopItemGift';
import NoGiftMessage from 'app/components/promo_action/gift/NoGiftMessage';
import SkinGift from 'app/components/promo_action/gift/SkinGift';
import MoneyGift from 'app/components/promo_action/gift/MoneyGift';
import GiftProgressBar from 'app/components/promo_action/gift/GiftProgressBar';
import MyHeroLink from 'app/components/common/MyHeroLink';
import {modalsStore} from 'app/store/global';

export default class OpenGiftPopup extends PureComponent {
    static GIFT_TYPES = {
        MONEY: 0,
        SHOP_ITEM: 5,
        SKIN: 4
    };

    state = {
        closeHidden: false,
        finished: false,
        loading: true
    };

    componentDidMount = () => {
        preventEscPopupClose();
        new Post({
            url: getLinkPath('/promo_actions/take_part')
        }).execute()
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        if (data.success) {
                            const closeHidden = this.constructor.GIFT_TYPES.SKIN === data.type_id;
                            this.setState({
                                closeHidden,
                                message: `${data.your_gift_is}: ${data.name}`,
                                response: data,
                                success: true
                            });
                            if (!closeHidden) {
                                this.showClose();
                            }
                        } else {
                            this.setState({
                                finished: true,
                                message: data.message,
                                success: false
                            });
                        }
                        updateInfo();
                    });
                } else {
                    this.handleClosePopup();
                    modalConfirm(I18n.t('server_error_try_again'), window.openGift);
                }
            })
            .finally(() => this.setState({loading: false}));
    };

    componentWillUnmount = () => {
        enableEscPopupClose();
    };

    showClose = () => {
        this.setState({closeHidden: false});
        enableEscPopupClose();
    };

    afterProgressFinish = () => this.setState({finished: true});

    renderGiftItem = () => {
        switch (this.state.response.type_id) {
        case this.constructor.GIFT_TYPES.SKIN:
            return (
                <SkinGift
                    {...this.state.response}
                    afterFinish={this.afterFinishSkinGift}
                    showClose={this.showClose}
                />
            );
        case this.constructor.GIFT_TYPES.SHOP_ITEM:
            return (
                <ShopItemGift
                    {...this.state.response}
                    id={this.props.popupId}
                    message={this.state.message}
                    handleClosePopup={this.handleClosePopup}
                />
            );
        case this.constructor.GIFT_TYPES.MONEY:
            return <MoneyGift {...this.state.response} />;
        default:
            return null;
        }
    };

    finishedRender = () => <div className="simple-popup center simple-popup--big">
        {this.state.success
            ? <div>
                <div className="text-align-center">
                    <div className="simple-popup__title mark">{this.state.response.congratulations}</div>
                    <div className="simple-popup__text">{this.state.message}</div>
                </div>
                {this.state.response.inventory_item && <>
                    {this.state.response.inventory_item?.image?.url &&
                            <div className="simple-popup__gift-image">
                                <img src={this.state.response.inventory_item.image.url} />
                            </div>}
                    <MyHeroLink/>
                </>}
                {this.renderGiftItem()}
            </div>
            : <NoGiftMessage message={this.state.message} faqPath={this.faqPath()}/>}
    </div>;

    afterFinishSkinGift = name => this.setState({message: `${this.state.response.your_gift_is}: ${name}`});

    handleClosePopup = () => modalsStore.hideModal(this.props.popupId);

    faqPath = () => {
        const {promoAction} = this.props;

        switch (promoAction) {
        case 'PromoActionBeer':
            return '/faq/stpatrick';
        case 'PromoActionCocktail':
            return '/faq/egb-sunshine-party';
        case 'PromoActionOktoberfest':
            return '/faq/therese_von_bayern';
        case 'PromoActionPresent':
            return '/faq/gifts';
        default:
            return null;
        }
    };

    loadingContent = () => {
        if (this.state.loading) {
            return <div className="simple-popup center simple-popup--big">
                <div className="loader"/>
            </div>;
        }
        return this.state.finished
            ? this.finishedRender()
            : <GiftProgressBar delayTime={3600} afterFinish={this.afterProgressFinish}/>;
    };

    hasHideCloseButton = () => this.state.closeHidden || this.state.response &&
        !this.state.finished &&
        [this.constructor.GIFT_TYPES.SKIN, this.constructor.GIFT_TYPES.SHOP_ITEM].includes(
            this.state.response.type_id
        );

    render = () => <PopupDialog
        id={this.props.popupId}
        hideCloseButton={this.hasHideCloseButton()}
    >
        {this.loadingContent()}
    </PopupDialog>;
}
