import InventoryItemInterface from 'app/interfaces/InventoryItemInterface';
import InventoryItemsEffectInterface from 'app/interfaces/InventoryItemsEffectInterface';
import {UNPROCESSABLE_ENTITY} from 'app/utils/HttpStatuses';
import {action, computed, makeObservable, observable} from 'mobx';
import {getLinkPath, slowConnection, slowConnectionAndError} from 'app/utils';
import $ from 'jquery';
import {serverError} from '../utils';
import reduce from 'lodash/reduce';
import uniq from 'lodash/uniq';
import {equippedAtDescendingSort} from 'app/components/my_profile/artifacts/utils';
import {steamTradeStore} from './global';
import UserInterface from 'app/interfaces/UserInterface';
import Get from 'app/utils/Get';
import {updateOrAddObject} from 'app/utils/updateArraysObject';

const LOOTBOX_TYPE = 'lootbox';
const ITEMS_COUNT_IN_ROW = 10;
export const OPEN_LOOTBOX_UPDATE_TIMEOUT = 6000;
export const DELAY_BEFORE_OPENING_LOOTBOX = 400;

interface UpdateInventoryType {
    inventory_items?: InventoryItemInterface[],
    public_inventory?: boolean,
    trade_enabled?: boolean,
    trade_token_link?: string,
    inventory_items_effects?: InventoryItemsEffectInterface[],
}

interface SoundsType {
    line_closed_sound: string
    line_created_sound: string,
    notification_sound: string,
    stream_sound: string,
}

export class ArtifactStore {
    constructor() {
        this.updateInventory({});
        makeObservable(this);
    }

    @observable artifacts: InventoryItemInterface[] = [];

    @observable inventory_items: InventoryItemInterface[] = [];

    @observable inventory_items_effects: InventoryItemsEffectInterface[] = [];

    @observable publicInventory: boolean = false;

    @observable tradeEnabled: boolean = false;

    @observable tradeTokenLink: string = '';

    @observable loading: boolean = false;

    @observable name: string = '';

    @observable denied: boolean = false;

    @observable user: string = '';

    @action
    updateInventory({
        inventory_items,
        public_inventory,
        trade_enabled,
        trade_token_link,
        inventory_items_effects
    }: UpdateInventoryType): void {
        this.artifacts = inventory_items || [];
        this.inventory_items = inventory_items || [];
        this.publicInventory = public_inventory || false;
        this.tradeEnabled = trade_enabled || false;
        this.tradeTokenLink = trade_token_link || '';
        this.inventory_items_effects = inventory_items_effects || [];
    }

    @action
    getUserInventory(): void {
        new Get({url: getLinkPath('/my_profile/inventory_items')})
            .execute()
            .then(data => data.json())
            .then(data => this.updateStore({...data}))
            .finally(() => {
                this.setLoading(false);
            });
    }

    @action
    setLoading(value: boolean): void {
        this.loading = value;
    }

    @action
    setDenied(value: boolean): void {
        this.denied = value;
    }

    @action
    setUser(user: string): void {
        this.user = user;
    }

    @action
    updateStore({inventory_items, inventory_items_effects}:
                    {inventory_items: InventoryItemInterface[],
                        inventory_items_effects: InventoryItemsEffectInterface[]}): void {
        if (inventory_items) {
            this.inventory_items = inventory_items;
            this.artifacts = inventory_items;
        }
        if (inventory_items_effects) {
            this.inventory_items_effects = inventory_items_effects;
        }
    }

    @action
    updateArtifacts(artifacts: InventoryItemInterface[]): void {
        this.updateInventoryItems({inventory_items: artifacts});
    }

    @action
    updateInventoryItems({inventory_items}: UpdateInventoryType): void {
        this.inventory_items = inventory_items;
    }

    @action
    updateElementCollectionItems(item: InventoryItemInterface): void {
        updateOrAddObject(this, 'inventory_items', item);
    }

    @action
    updatePublicInventory(publicInventory: boolean): void {
        this.publicInventory = publicInventory;
    }

    @action
    updateTradeEnabled({trade_enabled, trade_token_link}: UpdateInventoryType): void {
        this.tradeEnabled = trade_enabled;
        this.tradeTokenLink = trade_token_link;
    }

    @action
    setTradeEnabled(value:boolean): void {
        this.tradeEnabled = value;
    }

    @action
    updateTradeTokenLink(tradeTokenLink: string): void {
        this.tradeTokenLink = tradeTokenLink;
    }

    use(item: InventoryItemInterface, continue_equipping = false, show_loading = true) {
        const {item_type, id} = item;
        const url = item_type === LOOTBOX_TYPE
            ? `/artifacts/${id}/use`
            : `/inventory/items/artifacts/${id}/use`;

        return slowConnection(
            $.post(url,
                {continue_equipping},
                response => {
                    if (item_type === LOOTBOX_TYPE) {
                        this.updateInventoryItems(response);
                    } else {
                        this.updateStore(response);
                    }
                }),
            false,
            show_loading
        );
    }

    disassemble(
        artifact: InventoryItemInterface,
        onSuccess: () => void,
        onComplete: () => void,
        onError: (msg: string) => void
    ): void {
        slowConnection($.ajax(`/artifacts/${artifact.id}/disassemble`, {
            error: xhr => {
                if (xhr.status === UNPROCESSABLE_ENTITY) {
                    onError(Object.values(xhr.responseJSON)[0][0]);
                } else {
                    onComplete();
                    serverError();
                }
            },
            method: 'POST',
            success: data => {
                this.updateStore(data);
                onSuccess();
                onComplete();
            }
        }));
    }

    assemble(recipeId: number, onSuccess: () => void, onError: (string) => void): void {
        slowConnection($.ajax('/artifacts/assemble', {
            data: {id: recipeId},
            error: xhr => {
                if (xhr.status === UNPROCESSABLE_ENTITY) {
                    onError(Object.values(xhr.responseJSON)[0][0]);
                } else {
                    serverError();
                }
            },
            method: 'POST',
            success: data => {
                this.updateStore(data);
                onSuccess();
            }
        }));
    }

    sell(artifact: InventoryItemInterface, artifactOrderParams, successCallback = () => {}): void {
        slowConnectionAndError($.ajax(getLinkPath('/artifact_orders'), {
            complete: successCallback,
            data: {artifact_order: artifactOrderParams},
            success: response => this.updateArtifacts(response),
            type: 'POST'
        }));
    }

    sendToUser(artifact: InventoryItemInterface, user: UserInterface, quantity: number, success: () => void, error: () => void): void {
        slowConnection($.ajax(`/artifacts/${artifact.id}/send_user`, {
            data: {
                quantity,
                user
            },
            error,
            method: 'POST',
            success: data => {
                this.updateStore(data);
                success();
            }
        }));
    }

    changePublicInventory(value: boolean): void {
        slowConnectionAndError($.ajax('/artifacts/public_inventory', {
            data: {public_inventory: value},
            success: response => this.updatePublicInventory(response.public_inventory),
            type: 'POST'
        }));
    }

    changeTradeEnabled(value: boolean): void {
        slowConnectionAndError($.ajax('/trades/trade_enabled', {
            data: {trade_enabled: value},
            success: response => this.updateTradeEnabled(response),
            type: 'POST'
        }));
    }

    changeTradeTokenLink(): void {
        slowConnectionAndError($.ajax('/trades/create_trade_link', {
            success: response => this.updateTradeTokenLink(response.trade_token_link),
            type: 'POST'
        }));
    }


    @computed
    get helmet(): InventoryItemInterface[] {
        return this.inventory_items.filter(this.equippedWith('helmet'));
    }

    @computed
    get armor(): InventoryItemInterface[] {
        return this.inventory_items.filter(this.equippedWith('armor'));
    }

    @computed
    get sword(): InventoryItemInterface[] {
        return this.inventory_items.filter(this.equippedWith('sword'));
    }

    @computed
    get shield(): InventoryItemInterface[] {
        return this.inventory_items.filter(this.equippedWith('shield'));
    }

    @computed
    get cloak(): InventoryItemInterface[] {
        return this.inventory_items.filter(this.equippedWith('cloak'));
    }

    @computed
    get boots(): InventoryItemInterface[] {
        return this.inventory_items.filter(this.equippedWith('boots'));
    }

    @computed
    get inventory(): InventoryItemInterface[] {
        return this.inventory_items.filter(i => !i?.equipped && !i?.in_market);
    }

    @computed
    get betArtifacts(): InventoryItemInterface[] {
        return this.inventory_items.filter(
            i => (i?.item_type === 'artifact' || i?.item_type === 'scroll_artifact') && i?.bet_available
        );
    }

    @computed
    get inventoryInMarket(): InventoryItemInterface[] {
        return this.inventory_items.filter(inventory_item => inventory_item?.in_market);
    }

    @computed
    get effects(): InventoryItemsEffectInterface[] {
        return this.inventory_items_effects;
    }

    @computed
    get artifactEffects(): InventoryItemsEffectInterface[] {
        return this.effects.filter(effect => effect.item_type === 'artifact');
    }

    @computed
    get scrollEffects(): InventoryItemsEffectInterface[] {
        return this.effects.filter(effect => effect.item_type === 'scroll_artifact');
    }

    @computed
    get emptySlots(): number[] {
        return [
            ...Array(
                Math.max(ITEMS_COUNT_IN_ROW - this.inventory.length - (steamTradeStore.steam_trades_count || 0), 0)
            )
        ];
    }

    @computed
    get sounds(): SoundsType {
        const sound_artifacts = equippedAtDescendingSort(this.inventory_items
            .filter(a => a.equipped && a.stream_sound && a.stream_sound.url));
        const {
            stream_sound, line_created_sound,
            line_closed_sound, notification_sound
        } = sound_artifacts[sound_artifacts.length - 1] || {};

        if (sound_artifacts.length === 0) {
            return null;
        }

        return {
            line_closed_sound: line_closed_sound.url,
            line_created_sound: line_created_sound.url,
            notification_sound: notification_sound.url,
            stream_sound: stream_sound.url
        };
    }

    @computed
    get equipped(): InventoryItemInterface[] {
        return this.inventory_items.filter(inventory_item => inventory_item?.equipped);
    }

    @computed
    get smileFilenames(): string[] {
        return uniq(
            this.equipped.reduce((accumulator, inventory_item) => accumulator
                .concat(inventory_item.smiles.map(smile => smile.filename)),
            [])
        );
    }

    @computed
    get multiEquipment(): number {
        const res = this.equipped.find(a => a.multi_equipment > 0);
        return res && res.multi_equipment;
    }

    @computed
    get incubatorInventoryItemsEffect(): InventoryItemsEffectInterface[] {
        return this.inventory_items_effects
            .filter(inventory_item => inventory_item.recipe && inventory_item.recipe.incubator);
    }

    equippedWith(bodyPart: string): (item: InventoryItemInterface) => boolean {
        return a => a?.body_part === bodyPart && a?.equipped;
    }

    hasComponentsForCraft(recipe) {
        return reduce(recipe.components, (accumulator, recipe) => accumulator &&
            this.hasArtifact(recipe.id) !== undefined, true);
    }

    hasArtifact(recipeId) {
        return this.artifacts.find(a => a.recipe.id === recipeId);
    }

    hasInventoryEffectIncubator(): boolean {
        const items = this.incubatorInventoryItemsEffect;
        return items && items.length > 0;
    }
}
