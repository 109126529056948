import React, {PureComponent} from 'react';
import Progress from 'app/components/promo_action/compendium/progress';
import TableLeaders from 'app/components/promo_action/compendium/table_leaders';
import AsideLinks from 'app/components/promo_action/compendium/strarted_components/AsideLinks';


export default class WithUserComponent extends PureComponent {
    render() {
        const {
            predictions_available,
            prediction_points,
            isLeaderboardAvailable,
            scatterText
        } = this.props;

        return <>
            <div className="compendium-aside__content compendium-aside__content_left table">
                <Progress {...this.props}/>

                <AsideLinks
                    predictions_available={predictions_available}
                    prediction_points={prediction_points}
                    scatterText={scatterText}
                />
            </div>
            { isLeaderboardAvailable && <TableLeaders {...this.props}/> }
        </>;
    }
}
