import React from 'react';
import I18n from 'app/i18n';
import {getLinkPath, isDesktop} from 'app/utils';
import Get from 'app/utils/Get';
import Post from 'app/utils/Post';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {updateInfo} from 'app/common';
import {toastSuccess} from 'app/components/toasts/liteToast';
import {modalsStore} from 'app/store/global';

const SEND_TO_USER_TYPE = 0;
const SEND_TO_EMAIL_TYPE = 1;
const USER_NAME_LENGTH = 32;
const EMAIL_NAME_LENGTH = 52;
const MIN_STRING_LENGTH_FOR_SEARCH = 4;
const DELAY_AFTER_OPENING = 350;
const FIRST_COUNT_TO_SENT = 1;
const SECOND_COUNT_TO_SENT = 2;
const THIRD_COUNT_TO_SENT = 3;
const NUMBERS_PRESENTS_TO_SENT = [
    FIRST_COUNT_TO_SENT,
    SECOND_COUNT_TO_SENT,
    THIRD_COUNT_TO_SENT
];

export const findUserOrEmail = (value, callback, type) => {
    const checkType = typeof type === 'undefined' ? SEND_TO_USER_TYPE : type;

    new Get({
        params: {id: value.trim()},
        url: `/gift/check_${checkType === SEND_TO_USER_TYPE ? 'name' : 'email'}`
    }).execute()
        .then(response => {
            if (response.ok) {
                response.json().then(data => callback(data.success));
            }
        });
};

const activeTabClass = (type, currentType) => type === currentType ? ' active-link' : '';

export default class SendForm extends React.PureComponent {
    constructor(props) {
        super(props);
        const {userCredential, userName} = props;
        this.state = {
            checked: typeof userCredential !== 'undefined',
            selectedCountGift: 1,
            type: SEND_TO_USER_TYPE,
            userCredential: userName
        };
        this.userCredentialInput = React.createRef();
        this.findUserOrEmail = this.findUserOrEmail.bind(this);
        this.clickTabHandler = this.clickTabHandler.bind(this);
        this.sendGift = this.sendGift.bind(this);
        this.changeUserCredentialHandler = this.changeUserCredentialHandler.bind(this);
    }

    scrollToBottom = () => {
        const popup = document.querySelector('.popup__content--gift');
        setTimeout(() => popup.scrollIntoView({behavior: 'smooth', block: 'end'}), DELAY_AFTER_OPENING);
    };

    componentDidMount() {
        if (this.state.userCredential && this.state.userCredential.length > MIN_STRING_LENGTH_FOR_SEARCH) {
            findUserOrEmail(this.state.userCredential, success => {
                this.setState({checked: success});
            }, this.state.type);
        }

        const input = document.getElementById('send_gift_username_or_email');

        if (input) {
            input.addEventListener('click', this.scrollToBottom);
        }
    }

    componentWillUnmount() {
        const input = document.getElementById('send_gift_username_or_email');

        if (input) {
            input.removeEventListener('click', this.scrollToBottom);
        }
        if (!isDesktop()) {
            window.scrollTo({behavior: 'smooth', top: 0});
        }
    }

    clickTabHandler(type, e) {
        e && e.preventDefault();
        this.setState({
            checked: false,
            error: null,
            selectedCountGift: 1,
            type,
            userCredential: ''
        });
    }

    findUserOrEmail(e) {
        if (e.target.value.trim().length < MIN_STRING_LENGTH_FOR_SEARCH) {
            this.setState({error: this.setState({checked: false})});
            return;
        }

        if (this.state.error) {
            this.setState({error: null});
        }
        findUserOrEmail(e.target.value, success => {
            this.setState({checked: success});
        }, this.state.type);
    }

    sendGift(event) {
        event.preventDefault();
        new Post({
            params: {
                count: this.state.selectedCountGift,
                receiver: this.state.userCredential,
                send_type: this.state.type
            },
            url: getLinkPath('/gift/send_gift')
        }).execute()
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        if (data.success) {
                            modalsStore.hideModal(this.props.popupId);
                            toastSuccess(data.message);
                        } else {
                            this.setState({error: data.error});
                        }
                        updateInfo();
                    });
                }
            });
    }

    changeUserCredentialHandler(e) {
        const exceedsLength = event => event.target.value.trim().length > (this.state.type === SEND_TO_USER_TYPE
            ? USER_NAME_LENGTH
            : EMAIL_NAME_LENGTH);

        if (e && !exceedsLength(e)) {
            this.setState({userCredential: e.target.value.trim()});
            this.findUserOrEmail(e);
        }
    }

    changeCountGiftHandler(e, selectedCountGift) {
        e.preventDefault();

        if (selectedCountGift <= this.props.numberOfGifts) {
            this.setState({selectedCountGift});
        }
    }

    userCredentialInputClasses = () => {
        const classes = ['popup-form__row'];

        if (this.state.error) {
            classes.push('field-error');
        }

        return classes.join(' ');
    };

    renderNumberButton = num => {
        const isAvailable = num > this.props.numberOfGifts;
        const className = `field__count-btn${num === this.state.selectedCountGift ? ' field__count-btn_active' : ''}`;

        return <button
            type="button"
            className={className}
            disabled={isAvailable}
            key={num}
            onClick={e => this.changeCountGiftHandler(e, num)}
        >
            {I18n.t('gifts.prize', {count: num})}
        </button>;
    };

    render() {
        const nameCheckedClass = this.state.checked ? ' field__input--exist-state' : ' field__input--inexist-state';
        const signName = this.state.checked ? 'svg-check' : 'svg-close';
        const labelText = I18n.t(this.state.type === SEND_TO_USER_TYPE
            ? 'please_enter_user_nickname'
            : 'please_enter_user_email');

        return <PopupDialog id={this.props.popupId}>
            <div className="popup__content popup__content--small popup__content--gift">
                <div className="modal-popup-content">
                    <div className="tabs">
                        <div className="tabs__header tabs__header--auth">
                            <div className="tabs__inner__header">
                                <a
                                    href="#"
                                    onClick={this.clickTabHandler.bind(this, SEND_TO_USER_TYPE)}
                                    className={`tabs__link tabs__link--btn tabs__link--popup${
                                        activeTabClass(this.state.type, SEND_TO_USER_TYPE)}`}
                                >EGB User</a>
                                <a
                                    href="#"
                                    onClick={this.clickTabHandler.bind(this, SEND_TO_EMAIL_TYPE)}
                                    className={`tabs__link tabs__link--btn tabs__link--popup${
                                        activeTabClass(this.state.type, SEND_TO_EMAIL_TYPE)}`}>Email</a>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={this.sendGift}>
                        <div className={this.userCredentialInputClasses()}>
                            <label htmlFor="send_gift_username_or_email">{labelText}</label>
                            <div className="field">
                                <div className="field__wrap">
                                    <input
                                        id="send_gift_username_or_email"
                                        type="text"
                                        ref={this.userCredentialInput}
                                        onChange={this.changeUserCredentialHandler}
                                        value={this.state.userCredential ?? ''}
                                        className={`field__input${ nameCheckedClass}`}
                                        name="send_gift_username_or_email"
                                    />
                                    <span className="field__state">
                                        <svg height="12px" width="12px">
                                            <use xlinkHref={`#${signName}`}/>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="popup-form__row">
                            <label htmlFor="send_gift_count_gift">{I18n.t('number_prizes_to_transfer')}</label>
                            <div className="field">
                                <div className="field__count" name="send_gift_count_gift" id="send_gift_count_gift">
                                    {NUMBERS_PRESENTS_TO_SENT.map(num => this.renderNumberButton(num))}
                                </div>
                            </div>
                        </div>
                        <div className="popup-form__btn popup-form__btn_send">
                            <button
                                className="btn"
                                disabled={!this.state.checked}
                                type="submit"
                            >
                                {I18n.t('send_gift')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </PopupDialog>;
    }
}
