import React, {PureComponent} from 'react';
import LeaderLine, {LeaderLineProps} from './leader_line';

interface LeaderLinesProps {
    ratings: LeaderLineProps[];
}

export default class LeaderLines extends PureComponent<LeaderLinesProps> {
    render(): JSX.Element {
        const {ratings} = this.props;
        return <>{ratings.map((rating, i) => <LeaderLine key={i} {...rating} />)}</>;
    }
}
