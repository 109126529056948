import TableLeadersRow from 'app/components/promo_action/compendium/table_leaders/TableLeadersRow';
import React, {PureComponent} from 'react';

export default class TableLeaders extends PureComponent {
    render() {
        const {ratings} = this.props;

        return <div className="compendium-aside__table-leaders aside-table table-leaders">
            <div className="aside-table__body table-leaders__body">
                {ratings.map(rating => <TableLeadersRow key={rating.place} {...rating}/>)}
            </div>
        </div>;
    }
}
