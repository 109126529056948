
import {WHITE, lineGamer} from 'app/lines/line_helpers';
import LineInterface from 'app/interfaces/LineInterface';

export interface GroupedData {
  [key: string]: LineInterface[];
}


export const groupByType = (nestedBets: LineInterface[], results: boolean, site_lang: string): GroupedData => nestedBets.reduce((acc, item) => {
    const gameInfo = lineGamer(item, 1);

    const key = item.type === 'Handicap' || item.type === 'NestedBet'
        ? gameInfo.langs && gameInfo.langs[site_lang] || gameInfo.game_name
        : item.type;

    if (!acc[key]) {
        acc[key] = [];
    }

    if (!item.hide && (item.color === WHITE && results || item.color !== WHITE)) {
        acc[key].push(item);
    }

    return acc;
}, {} as GroupedData);
