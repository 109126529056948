import {promoActionStore, userStore} from 'app/store/global';
import Get from 'app/utils/Get';
import React, {Fragment, PureComponent} from 'react';
import I18n from 'app/i18n';
import {localePromoName, promoUrl, isGuest} from 'app/utils';
import TableEmptyState from 'app/components/table/empty_state';
import TradeLink from './rating/trade_link';
import LeaderLines from './rating/leader_lines';
import omit from 'lodash/omit';
import SmartLoader from 'app/components/reusable/SmartLoader';
import Post from 'app/utils/Post';
import BannerButtonPrize, {PrizeInterface} from 'app/components/compendium/banner/BannerButtonPrize';
import {LeaderLineProps} from './rating/leader_line';

type RatingProps = {
    showNickHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

type RatingState = {
    loadInfo: boolean;
    loading: boolean;
    promo_action: { total_place_count: number };
    ratings: LeaderLineProps[];
    user_rating: { place?: number; rating_points?: number; show_nick?: boolean; prize?: PrizeInterface };
    place_count?: number;
};

export default class Rating extends PureComponent<RatingProps, RatingState> {
    constructor(props: RatingProps) {
        super(props);
        this.state = {
            loadInfo: false,
            loading: true,
            place_count: 0,
            promo_action: {total_place_count: 0},
            ratings: [],
            user_rating: {}
        };

        this.showNickHandler = this.showNickHandler.bind(this);
    }

    componentDidMount(): void {
        new Get({
            url: window.location.pathname
        }).execute()
            .then(response => response.json())
            .then(data => this.setState(data))
            .finally(() => this.setState({loading: false}));
    }

    showNickHandler(e: React.ChangeEvent<HTMLInputElement>): void {
        e.preventDefault();
        this.setState({loadInfo: true});

        new Post({
            url: promoUrl('show_nick')
        })
            .execute()
            .then(response => response.json())
            .then(response => {
                userStore.update({user: response.user});
                this.setState(omit(response, 'user'));
                this.setState({loadInfo: false});
            });
    }

    get personal(): { place?: number; rating_points?: number; show_nick?: boolean; prize?: PrizeInterface } {
        return this.state.user_rating;
    }

    get remainsForTop(): number {
        let {ratings} = this.state;
        ratings = ratings.filter(rating => rating);
        return ((ratings[ratings.length - 1] || {}).rating_points || 0) - (this.personal.rating_points || 0);
    }

    inTop = (): boolean => (this.personal.place ?? Infinity) <= (this.state.promo_action.total_place_count ?? 0);

    render(): JSX.Element {
        const {promo_action: {total_place_count}, loading, loadInfo, place_count} = this.state;
        const {place, rating_points, show_nick, prize} = this.state.user_rating;

        return <div className="compendium">
            <div className="compendium__title">{I18n.t(`user.promo_action.${localePromoName()}.rating`)}</div>
            {loading
                ? <SmartLoader/>
                : <Fragment>
                    {!isGuest() && place && <BannerButtonPrize
                        points={rating_points}
                        place={place}
                        showName={show_nick}
                        prize={prize}
                        loading={loadInfo}
                        showNickHandler={this.showNickHandler}
                        totalPlaceCount={total_place_count}
                        placeCount={place_count}
                    />}
                    <TradeLink inTop={this.inTop()}/>
                    <div className="compendium__leaders-table">
                        <table className="compendium-leaders-table">
                            <thead>
                                <tr>
                                    <th>{I18n.t('table.place_title')}</th>
                                    <th>{I18n.t('table.nickname_title')}</th>
                                    <th>{promoActionStore.compendiumIsFinished()
                                        ? I18n.t('table.prize')
                                        : I18n.t('table.potential_prize')}</th>
                                    <th>RP</th>
                                </tr>
                            </thead>
                            <tbody>
                                <LeaderLines ratings={this.state.ratings}/>
                            </tbody>
                        </table>
                        <TableEmptyState
                            arrayLength={this.state.ratings.length}
                            text={I18n.t('empty_table.there_are_no_leaders_here_yet')}
                        />
                    </div>
                </Fragment>}
        </div>;
    }
}
