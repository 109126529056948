import React, {Fragment, PureComponent} from 'react';
import $ from 'jquery';
import {saveTradeLinkHandler, slowConnectionAndError} from 'app/utils';
import {Store} from 'app/store';
import ChooseField from 'app/components/promo_action/gift/choose_field';
import {artifactName} from 'app/components/my_profile/artifacts/utils';
import I18n from 'app/i18n';
import SellNowSteamSkin from 'app/components/common/SellNowSteamSkin';
import MyHeroLink from 'app/components/common/MyHeroLink';
import {artifactStore, userStore} from 'app/store/global';

const HALF_SECOND = 500,
    SORT_VALUE = 0.5,
    THREE_SECONDS = 3000,
    TWO_SECONDS = 2000;

export default class SkinGift extends PureComponent {
    trade_link = React.createRef();

    state = {
        finished: false,
        skins: [],
        trade_link_saved: false,
        win: null
    };

    chooseSkin = type => {
        const {skin_gift, type_id} = this.props;
        slowConnectionAndError($.post('/gift/choose_skin', {
            id: skin_gift,
            type_id,
            value: type
        }, data => {
            if (data.win) {
                const res = [
                    {
                        artifact: data.win.artifact,
                        title: data.win.name,
                        url: data.win.image.url
                    }
                ];
                data.other.forEach((v, i) => {
                    res.push({
                        artifact: v.artifact,
                        title: v.name,
                        to_hide_class: `skin-fade-out${i}`,
                        url: v.image.url
                    });
                });
                res.sort(() => SORT_VALUE - Math.random());
                this.setState({
                    skins: res,
                    win: data
                });

                res.forEach(
                    (skin, i) => setTimeout(() => this.fadeOutSkin(i), i * THREE_SECONDS)
                );
                setTimeout(this.afterFinish, (res.length - 1) * THREE_SECONDS);
            } else {
                this.afterFinish(data.message);
                this.setState({win: data});
            }
            artifactStore.updateElementCollectionItems(data.win.artifact);
            this.props.showClose();
        }));

        this.setState(() => ({type}));
    };

    fadeOutSkin = index => {
        const element = document.querySelector(`.skin-fade-out${index}`);

        if (element) {
            element.style.transition = `opacity ${TWO_SECONDS}ms`;
            setTimeout(() => {
                element.style.opacity = 0;
            }, HALF_SECOND);

            setTimeout(() => {
                element.style.visibility = 'hidden';
                const hideItems = document.querySelectorAll('.gift-choose__item_hide');
                hideItems.forEach(item => {
                    if (item.querySelector(`.skin-fade-out${index}`)) {
                        item.style.display = 'none';
                    }
                });
            }, TWO_SECONDS);
        }
    };

    saveTradeLink = () => {
        slowConnectionAndError(saveTradeLinkHandler(this.trade_link.current.value, {
            statusCode: {
                200: response => {
                    userStore.update({user: response});
                    this.setState({trade_link_saved: true});
                }
            }
        }));
    };

    afterFinish = message => {
        this.props.afterFinish(message || this.state.win.win.name);
        this.setState({finished: true});
    };

    render = () => {
        const gifts = [];
        const {cs_skin, dota_skin, artifact_skin} = this.props;

        if (!this.state.win) {
            cs_skin && gifts.push(<ChooseField
                {...this.props}
                chooseSkin={this.chooseSkin}
                value="cs"
            />);
            dota_skin && gifts.push(<ChooseField
                {...this.props}
                chooseSkin={this.chooseSkin}
                value="dota"
            />);
            artifact_skin && gifts.push(<ChooseField
                {...this.props}
                chooseSkin={this.chooseSkin}
                value="artifact"
            />);
        }
        return <Fragment>
            <div className="gift-choose">
                {gifts.map((v, i) => <div className="field gift-choose__item" key={`sk-gift${i}`}>{v}</div>)}
            </div>
            {this.renderWin()}
        </Fragment>;
    };

    prizeName = skin => skin.artifact && artifactName(skin.artifact) || skin.title;

    renderWin() {
        const {win, finished} = this.state;

        if (!win || win.message) {
            return null;
        }

        const {steam_skin, steam_trade, artifact} = win.win;

        return <Fragment>
            <div className="gift-popup-row gift-popup-row_skin">
                {this.state.skins.map((skin, i) => <div
                    className={`field gift-choose__item ${skin.to_hide_class ? 'gift-choose__item_hide' : ''}`}
                    key={`im${i}`}>
                    <div className={`field__choose ${skin.to_hide_class ? skin.to_hide_class : ''}`}>
                        <div className="field__choose-item">
                            <div className="gift-choose__content gift-choose__content--skin">
                                <img src={skin.url} alt={name} className="gift-choose__icon"/>
                                <div className="gift-choose__name">
                                    {this.prizeName(skin)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
            <div className="gift-popup-row">
                {finished && <SellNowSteamSkin steam_skin={steam_skin} steam_trade={steam_trade}/>}
                {finished && artifact && <MyHeroLink/>}
            </div>
            {!(this.state.trade_link_saved || this.state.type === 'artifact') && <div className="gift-popup-row gift-popup-row__exchange">
                <label htmlFor="trade_link">{I18n.t('trade_link')}</label>
                <div className="field">
                    <div className="field__wrap">
                        <input
                            name="trade_link"
                            id="trade_link"
                            ref={this.trade_link}
                            placeholder={I18n.t('trade_link')}
                            className="field__input"
                            defaultValue={Store.userTradeLink()}
                        />
                    </div>
                </div>
                <button className="btn" onClick={this.saveTradeLink}>{I18n.t('user.save')}</button>
            </div>}
        </Fragment>;
    }
}
