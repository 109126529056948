import React, {PureComponent} from 'react';
import Progress from 'app/components/promo_action/compendium/progress';
import I18n from 'app/i18n';
import {Link} from 'react-router-dom';
import {getPromoLinkPath} from 'app/utils';
import ScatterFAQ from 'app/components/promo_action/modules/scatter_faq';
import {goToMyHero} from 'app/utils/app_links';
import ConvertButton from 'app/components/my_profile/artifacts/artifact_popup/compendium/ConvertButton';

export default class WithoutUserComponent extends PureComponent {
    render() {
        return <div className="compendium-aside__content compendium-aside__content_left">
            <Progress
                {...this.props}
                styleTop={0}
            />
            <div className="compendium-aside__links">
                <div className="compendium-aside__link">
                    <svg height="16px" width="16px">
                        <use xlinkHref="#svg-predictions"/>
                    </svg>
                    <Link to={getPromoLinkPath('/predictions')}>
                        {I18n.t('compendium.compendium_table')} ({this.props.predictions_total})
                    </Link>
                </div>
                <div className="compendium-aside__link">
                    <svg height="16px" width="16px">
                        <use xlinkHref="#svg-table-leaders"/>
                    </svg>
                    <Link to={getPromoLinkPath('/rating')}>
                        {I18n.t('compendium.results_table')}
                    </Link>
                </div>
                <div className="compendium-aside__link">
                    <svg height="16px" width="16px">
                        <use xlinkHref="#svg-scatter"/>
                    </svg>
                    <ConvertButton onClickHandler={goToMyHero} classes={['compendium-aside__scatter-btn']}/>
                    <ScatterFAQ text={this.props.scatterText}/>
                </div>
            </div>
        </div>;
    }
}
