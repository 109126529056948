/* eslint-disable complexity */
import {ComponentClass} from 'react';
import P2PCashoutConfirm from 'app/components/toasts/Components/P2pCashoutConfirm';
import SiteNotificationToast from 'app/components/toasts/Components/SiteNotificationToast';
import FreeSpinToast from 'app/components/toasts/Components/FreeSpinToast';
import I18n from 'app/i18n';
import newBet from './Components/NewBet';
import limitIncreasedOrEnabled from './Components/LimitIncreasedOrEnabled';
import stream from './Components/Stream';
import betAccepted from './Components/BetAccepted';
import expressBetAccepted from './Components/ExpressBetAccepted';
import newMessage from './Components/NewMessage';
import LineInterface from 'app/interfaces/LineInterface';
import CashoutInterface from 'app/interfaces/CashoutInterface';
import SiteNotificationInterface from 'app/interfaces/SiteNotificationInterface';
import sendArtifactSuccess from './Components/SendArtifactSuccess';
import {TranslateOptions} from 'i18n-js';
import FreespinInterface from 'app/interfaces/Freespinsinterface';

interface LocaleNames {
    en: string
    ru: string
}

const localeName = (names: LocaleNames): string => {
    if (I18n.locale === 'ru') {
        return names.ru;
    }
    return names.en;
};

interface NotificationComponentType {
    content: ComponentClass
    sound: string
    status?: string
}

export const componentByType = (type: string): NotificationComponentType => {
    let content = null;
    let sound = null;
    switch (type) {
    case 'p2p_cashout_confirmation':
        content = P2PCashoutConfirm;
        sound = 'money_sound';
        break;
    case 'site_notification':
        content = SiteNotificationToast;
        break;
    case 'free_spin_notification':
        content = FreeSpinToast;
        break;
    default:
        return null;
    }
    return {
        content,
        sound
    };
};

export interface ComponentsDataArg extends LineInterface {
    type: string
    key: string
    payload: FreespinInterface
    data: {
        id: number

        subject: string

        sender_name: string

        game: string
        author_flag: string
        title: string

        count: number
        coef: number
        name: string

        artifact_name: string

        steam_skin_name: string

        names: LocaleNames

        express_id: number

        status: string
        skin_name: string

        recipe_id: number
        steam_trade_id: number
        slug: string

        amount: string
        line_id: string
        reason: {
            key: string
            params: TranslateOptions
        }
        gamers: string
    } & SiteNotificationInterface
    artifacts_count?: number
    bet_sum: number
    cashout: CashoutInterface
    receiver: string
    artifact_name: string
}

export const components = (data: ComponentsDataArg): NotificationComponentType => {
    let content = null;
    let sound = null;
    let status = null;
    switch (data.type) {
    case 'new_message_received':
        content = newMessage(data);
        sound = 'notification_sound';
        status = 'info';
        break;
    case 'promo_action_gift':
        content = I18n.t('promo_action_gift', {user_name: data.data.sender_name});
        break;
    case 'new_stream_added':
        content = stream(data);
        sound = 'stream_sound';
        break;
    case 'bet_win':
        sound = 'line_closed_sound';
        content = I18n.t('bet_win_toast', {
            amount: data.data.count,
            coef: data.data.coef,
            name: data.data.name
        });
        status = 'success';
        break;
    case 'artifact_bet_win':
        sound = 'line_closed_sound';
        content = I18n.t('bet_artifact_win_toast', {
            coef: data.data.coef,
            count: data.data.count,
            name: data.data.name
        });
        status = 'success';
        break;
    case 'multiple_bets_limit_have_been_enabled':
        content = limitIncreasedOrEnabled(data);
        break;
    case 'multiple_bet_limit_increased':
        content = limitIncreasedOrEnabled(data);
        break;
    case 'line_limit_increased':
        content = limitIncreasedOrEnabled(data);
        break;
    case 'item_received':
        content = I18n.t('item_received', {artifact_name: data.data.artifact_name});
        break;
    case 'money_received':
        content = I18n.t('money_received', {amount: data.data.amount});
        break;
    case 'steam_skin_purchased':
        content = I18n.t('you_have_successfully_purchased_steam_skin', {steam_skin_name: data.data.steam_skin_name});
        status = 'success';
        break;
    case 'new_line_added':
        sound = 'line_created_sound';
        content = newBet(data);
        break;
    case 'news_article_added':
        content = I18n.t('news_article_added', {new_article_name: localeName(data.data.names)});
        break;
    case 'promo_article_added':
        content = I18n.t('promo_article_added', {promo_article_name: localeName(data.data.names)});
        break;
    case 'promo_started':
        content = I18n.t('promo_started_body', {promo_name: localeName(data.data.names)});
        break;
    case 'bet_accepted':
        status = 'success';
        content = betAccepted(data);
        break;
    case 'express_bet_accepted':
        status = 'success';
        content = expressBetAccepted(data);
        break;
    case 'leave_lootbox_page':
        content = I18n.t('leave_lootbox_page');
        break;
    case 'giveaway_confirm_user':
        content = I18n.t('giveaway_confirm');
        status = 'error';
        break;
    case 'disabled_country':
        content = I18n.t('disabled_country_toast');
        status = 'error';
        break;
    case 'send_artifact_success':
        status = 'success';
        content = sendArtifactSuccess(data);
        break;
    case 'suggestion_not_accepted':
        content = I18n.t('user.notification.suggestion.not_accepted.message', {
            amount: data.data.amount,
            gamers: data.data.gamers,
            reason: I18n.t(data.data.reason.key, data.data.reason.params)
        });
        status = 'info';
        break;
    case 'suggestion_placed':
        content = I18n.t('user.notification.suggestion.placed.message', {
            amount: data.data.amount,
            gamers: data.data.gamers,
            id: data.data.id
        });
        status = 'info';
        break;
    default:
        break;
    }
    return {
        content,
        sound,
        status
    };
};
