import React, {PureComponent} from 'react';
import Progress from './progress';
import I18n from 'app/i18n';
import {getPromoLinkPath, isGuest} from 'app/utils';
import {Link} from 'react-router-dom';
import TableLeaders from 'app/components/promo_action/compendium/table_leaders';

export default class FinishedComponent extends PureComponent {
    get isRenderable() {
        const {finished} = this.props;

        return finished;
    }


    render() {
        if (!this.isRenderable) {
            return null;
        }

        return <>
            <div className="compendium-aside__content compendium-aside__content_left">
                <Progress
                    {...this.props}
                    styleTop={0}
                />
                <div className="compendium-aside__links">
                    {!isGuest() && <div className="compendium-aside__link">
                        <svg height="16px" width="16px">
                            <use xlinkHref="#svg-my-predictions"/>
                        </svg>
                        <Link
                            to={getPromoLinkPath('/my_predictions')}
                        >
                            {I18n.t('compendium.my_predictions_title')}
                        </Link>
                    </div>}
                    <div className="compendium-aside__link">
                        <svg height="16px" width="16px">
                            <use xlinkHref="#svg-table-leaders"/>
                        </svg>
                        <Link to={getPromoLinkPath('/rating')}>
                            {I18n.t('compendium.results_table')}
                        </Link>
                    </div>
                </div>
            </div>
            {this.props.isLeaderboardAvailable &&
                <TableLeaders {...this.props}/>
            }
        </>;
    }
}
