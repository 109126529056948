import React from 'react';

const amount = str => str.replace(/\D/gu, '');

const NoGiftMessage = props => <div className="simple-popup__image">
    <img src={props.skin_gift_images.money} />
    <span>${amount(props.name)}</span>
</div>;

export default NoGiftMessage;
