import React from 'react';
import {slowConnectionPromise} from 'app/utils';
import Get from 'app/utils/Get';
import withRouter from 'app/utils/navigate/customWithRouter';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import I18n from 'app/i18n';

class StaticPage extends React.Component {
    state = {
        static_page_description: '',
        static_page_html: '',
        static_page_id: null
    };

    componentDidMount() {
        const {static_page_id} = this.state;

        if (static_page_id) {
            return;
        }
        this.loadStaticPageData();
    }

    setStaticPageParams(title, html, id) {
        this.setState({static_page_html: html, static_page_id: id, static_page_title: title});
    }

    loadStaticPageData() {
        slowConnectionPromise(
            new Get({
                url: this.props.params.id
            }).execute()
                .then(response => response.json())
                .then(data => this.setStaticPageParams(data.title, data.template, data.id)),
            false
        );
    }

    printPageAsPDF() {
        const input = document.getElementById('terms-and-conditions');

        html2canvas(input, {
            scrollY: -window.scrollY,
            windowHeight: input.scrollHeight,
            windowWidth: input.scrollWidth
        })
            .then(canvas => {
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = 210;
                const imgHeight = canvas.height * imgWidth / canvas.width;
                let heightLeft = imgHeight;

                let position = 0;

                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pdf.internal.pageSize.getHeight();

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pdf.internal.pageSize.getHeight();
                }

                pdf.save('terms-and-conditions.pdf');
            });
    }

    render() {
        const {static_page_title, static_page_html} = this.state;
        return <div>
            <div className="title">{static_page_title}</div>
            <div className="content__holder" id="terms-and-conditions" dangerouslySetInnerHTML={{__html: static_page_html}}/>
            <div className="content__holder cursor-pointer">
                <a onClick={this.printPageAsPDF}>{I18n.t('download_as_pdf')}</a>
            </div>
        </div>;
    }
}

export default withRouter(StaticPage);
