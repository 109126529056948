import React from 'react';
import I18n from 'app/i18n';
import {getLinkPath, timeStampToTimeShortFormat, isDesktop} from 'app/utils';
import Get from 'app/utils/Get';
import Post from 'app/utils/Post';
import {updateInfo} from 'app/common';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import {modalsStore} from 'app/store/global';

const DELAY_AFTER_OPENING = 350;

export default class SwapGift extends React.PureComponent {
    constructor(props) {
        super(props);
        const {userName} = props;
        this.state = {gifts: {},
            name: userName,
            nameExist: typeof userName !== 'undefined' || undefined};
        this.name = React.createRef();
    }

    scrollToBottom = () => {
        const popup = document.querySelector('.popup__content--gift');
        setTimeout(() => popup.scrollIntoView({behavior: 'smooth', block: 'end'}), DELAY_AFTER_OPENING);
    };

    componentDidMount() {
        this.updateRequests();
        const input = document.getElementById('swap_gift_username');

        if (input) {
            input.addEventListener('click', this.scrollToBottom);
        }
    }

    componentWillUnmount() {
        const input = document.getElementById('swap_gift_username');

        if (input) {
            input.removeEventListener('click', this.scrollToBottom);
        }
        if (!isDesktop()) {
            window.scrollTo({behavior: 'smooth', top: 0});
        }
    }

    updateRequests() {
        new Get({url: getLinkPath('/gift/form_of_swap')})
            .execute()
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        if (data.gifts.sender.length === 0 &&
                        data.gifts.receiver.length === 0 &&
                        data.gifts.swapable === 0) {
                            toastError(I18n.t('no_gifts'));
                            modalsStore.hideModal(this.props.popupId);
                        } else {
                            this.setState(data);
                        }
                    });
                }
            });
    }

    requestToSwap() {
        new Post({
            params: {
                receiver: this.state.name
            },
            url: getLinkPath('/gift/swap')
        }).execute()
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        if (data.success) {
                            this.updateRequests();
                            toastSuccess(data.message);
                        } else {
                            toastError(data.error);
                        }
                        updateInfo();
                    });
                }
            });
    }

    findUser(name) {
        if (name.length >= 4) {
            new Get({
                params: {id: name},
                url: getLinkPath('/gift/check_name')
            }).execute()
                .then(response => {
                    if (response.ok) {
                        response.json().then(data => {
                            if (data.success) {
                                this.setState({nameExist: data.success});
                            } else {
                                this.setState({nameExist: undefined});
                            }
                        });
                    }
                });
        } else {
            this.setState({nameExist: undefined});
        }
    }

    handleChange(state, e) {
        if (state === 'name' && e.target.value.length > 32) {
            return;
        }

        const obj = {...this.state};
        obj[state] = e.target.value.trim();
        this.setState(obj);
        if (state === 'name') {
            this.findUser(obj[state]);
        }
    }

    inputClass() {
        return this.state.nameExist ? 'field__input--exist-state' : 'field__input--inexist-state';
    }

    isUserSender(obj) {
        const senders = this.state.gifts.sender;
        return senders.filter(sender => sender === obj).length > 0;
    }

    mergeGifts() {
        const gifts = {...this.state.gifts};

        if (gifts.hasOwnProperty('sender') && gifts.hasOwnProperty('receiver')) {
            return gifts.receiver.concat(gifts.sender);
        }
        return [];
    }

    swapGiftAction(data, gift) {
        new Post({
            params: data,
            url: getLinkPath('/gift/swap_action')
        }).execute()
            .then(response => {
                if (response.ok) {
                    response.json().then(reponseData => {
                        if (reponseData.hasOwnProperty('error')) {
                            toastError(reponseData.error);
                        } else {
                            if (data.action_type === 'reject' && gift.sender !== settings.user.name) {
                                toastError(I18n.t('gifts.swap_was_denied'));
                            } else if (data.action_type === 'reject' && gift.sender === settings.user.name) {
                                toastError(I18n.t('gifts.swap_was_denied'));
                            } else if (data.action_type === 'accept') {
                                toastSuccess(I18n.t('gifts.swap_was_successful'));
                            }
                            this.updateRequests();
                        }
                        updateInfo();
                    });
                }
            });
    }

    reject(gift) {
        return this.swapGiftAction({id: gift.id, action_type: 'reject'}, gift);
    }

    render() {
        const signName = this.state.nameExist ? 'svg-check' : 'svg-close';

        return <PopupDialog id={this.props.popupId}>
            <div className="simple-popup simple-popup--big popup__content--gift">
                {this.mergeGifts().length > 0 &&
                    <div className="gift-exchange-offer">
                        <h3 className="gift-exchange-offer-title">{I18n.t('gifts.exchange_offers')}</h3>
                        <div className="gift-exchange-offer__content">
                            {this.mergeGifts().map(gift => <div className="gift-exchange-offer__item" key={gift.id}>
                                <div className="gift-exchange-offer__row">
                                    <div className="gift-exchange-offer__row-info">
                                        {I18n.t('gifts.created')}: <span>{timeStampToTimeShortFormat(gift.created_at)}</span>
                                    </div>
                                    <div className="gift-exchange-offer__row-info">
                                        {I18n.t('gifts.until')}: <span>{timeStampToTimeShortFormat(gift.expire)}</span>
                                    </div>
                                </div>
                                <div className="gift-exchange-offer__row">
                                    <p className="gift-exchange-offer__row-info">
                                        {this.isUserSender(gift)
                                            ? <>{I18n.t('gifts.to')}: <span>{gift.receiver}</span></>
                                            : <>{I18n.t('gifts.from')}: <span>{gift.sender}</span></>}
                                    </p>
                                    <div className="gift-exchange-offer__btns">
                                        {this.isUserSender(gift)
                                            ? null
                                            : <button
                                                className="gift-exchange-offer-btn"
                                                onClick={() => this.swapGiftAction({
                                                    id: gift.id,
                                                    action_type: 'accept'
                                                }, gift)}>
                                                {I18n.t('gifts.accept')}
                                            </button>
                                        }
                                        <button
                                            className="gift-exchange-offer-btn"
                                            onClick={() => this.reject(gift)}>
                                            {I18n.t('gifts.reject')}
                                        </button>
                                    </div>
                                </div>
                            </div>)
                            }
                        </div>
                    </div>}

                <form className="popup-form" onSubmit={e => {
                    e.preventDefault();
                    this.requestToSwap();
                }}>
                    <div className="field">
                        <label htmlFor="swap_gift_username">{I18n.t('please_enter_user_nickname')}</label>
                        <div className="field__wrap">
                            <input
                                id="swap_gift_username"
                                type="text"
                                value={this.state.name ?? ''}
                                ref={this.name}
                                className={`field__input ${this.inputClass()}`}
                                onChange={this.handleChange.bind(this, 'name')}
                                name="swap_gift_username"
                            />
                            <span type="button" className="field__state">
                                <svg height="12px" width="12px">
                                    <use xlinkHref={`#${signName}`}/>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className="popup-form__btn popup-form__btn_swap">
                        <button
                            className="btn"
                            disabled={!this.state.nameExist}
                            type="submit"
                        >
                            {I18n.t('swap_gift')}
                        </button>
                    </div>
                </form>
            </div>
        </PopupDialog>;
    }
}
