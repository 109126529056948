import React, {Component} from 'react';
import Progress from './progress';

export default class ScheduledComponent extends Component {
    get isRenderable() {
        const {start_date, currentTimeStore: {t}} = this.props;

        return start_date > t;
    }

    render() {
        if (!this.isRenderable) {
            return null;
        }


        return <div className="compendium-aside__content">
            <div className="compendium-aside__progress-column only-timer">
                <Progress
                    {...this.props}
                    styleTop={0}
                />
            </div>
        </div>;
    }
}
