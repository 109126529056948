import {action, computed, makeObservable, observable} from 'mobx';
import Websocket from 'modules/Websocket';
import _uniqueId from 'lodash/uniqueId';
import {userSignedIn} from '../utils';
import {ELEMENT_NOT_FOUND} from 'app/utils/Consts';
import {casinoGamesStore} from 'app/store/global';

const allowedTypes = [
    'artifact_bet_win',
    'bet_win',
    'bet_accepted',
    'betProgress',
    'express_bet_accepted',
    'free_spin_notification',
    'item_received',
    'money_received',
    'giveaway_confirm_user',
    'disabled_country',
    'leave_lootbox_page',
    'line_limit_increased',
    'message_received',
    'multiple_bets_limit_have_been_enabled',
    'multiple_bet_limit_increased',
    'new_line_added',
    'new_message_received',
    'new_stream_added',
    'news_article_added',
    'p2p_cashout_confirmation',
    'promo_article_added',
    'promo_started',
    'promo_action_gift',
    'send_artifact_success',
    'site_notification',
    'steam_skin_purchased',
    'subscription_browser_notifications',
    'suggestion_not_accepted',
    'suggestion_placed'
];

let subscription = null;

const ONE_SECOND = 1000;
const THIRTY_SECONDS = 30;
const defaultTimeout = THIRTY_SECONDS * ONE_SECOND;

class NotificationStoreClass {
    active = observable([], {deep: false});

    knownKeys = observable([]);

    constructor() {
        makeObservable(this);
    }

    @computed
    get activeNotify() {
        return this.active;
    }

   @action
   // eslint-disable-next-line max-statements
    add({force = false, timeout = true, ...payload}) {
        if (force === false && (!userSignedIn() || !window.settings.user.notifications)) {
            return;
        }

        const {key, type} = payload;

        if (!allowedTypes.includes(type)) {
            return;
        }

        if (key) {
            if (this.knownKeys.includes(key)) {
                return;
            }
            this.knownKeys.push(key);
        }

        payload.key = key || _uniqueId();

        if (timeout) {
            setTimeout(() => {
                const idx = this.active.indexOf(payload);

                if (idx !== ELEMENT_NOT_FOUND) {
                    this.active.splice(idx, 1);
                }
            }, Number.isFinite(timeout) ? timeout : defaultTimeout);
        }

        this.active.push(payload);
    }

   @action
   clear() {
       this.active.clear();
       this.knownKeys.clear();
   }

    @action
   listen(data) {
       if (data.event && data.event.includes('_create')) {
           if (data.type === 'new_stream_added' && casinoGamesStore.isGameActive) {
               return null;
           }
           return this.add({
               timeout: true,
               ...data
           });
       } else if (data.event && data.event.includes('_delete')) {
           return this.removeActiveKeys(data.payload.key);
       }
       return this.add({
           timeout: true,
           ...data
       });
   }

   @action
    removeActiveKeys(key) {
        const idx = this.active.findIndex(x => x.key === key);

        if (idx !== ELEMENT_NOT_FOUND) {
            this.active.splice(idx, 1);
        }
    }

   @action
   removeKnownKeys(key) {
       this.knownKeys.splice(this.knownKeys.indexOf(key), 1);
   }

   @action
   stopListening() {
       if (subscription) {
           Websocket.unsubscribe(subscription);
           subscription = null;
       }
   }
}

const NotificationStore = new NotificationStoreClass();

export default NotificationStore;
