/* eslint-disable new-cap */
import React, {Component} from 'react';
import {isGuest} from 'app/utils';
import {getToken} from 'app/services/SportbookService';
import {inject, observer} from 'mobx-react';
import {UserStore} from 'app/store/UserStore';
import {LANGUAGES} from 'app/utils/Consts';

const HIDE_SUPPORT_TIME = 200;

interface PropsInterface {
    userStore?: UserStore
}

@inject('userStore')
@observer
export default class Sport extends Component<PropsInterface> {
    autoHideTimer = null;

    componentDidMount():void {
        if (typeof window.Sportsbook !== 'function') {
            return;
        }

        window.Sportsbook('init', {
            container: '#sport-page',
            contentAlignment: {
                bottomOffsetElement: 'footer',
                enabled: false
            },
            getAuthToken: (): Promise<string> => getToken(window.USER_UI_DATA.user_id),
            iframeSrc: `${window.SITE_DATA.SPORTBOOK_URL}/${this.currentLocale()}`
        });
        window.Sportsbook('setAuthStatus', {authStatus: isGuest() ? 'NOT_LOGGED_IN' : 'LOGGED_IN'});

        this.hideSupport();
    }

    currentLocale = (): string => window.CURRENT_LOCALE === LANGUAGES.RU ? LANGUAGES.RU : LANGUAGES.EN;

    hideSupport = (): void => {
        const support = document.getElementById('hde-contact-container');

        if (support) {
            support.style.display = 'none';
        } else {
            this.autoHideTimer = setTimeout(this.hideSupport, HIDE_SUPPORT_TIME);
        }
    };

    showSupport = (): void => {
        this.autoHideTimer && clearTimeout(this.autoHideTimer);
        const support = document.getElementById('hde-contact-container');

        if (support) {
            support.style.display = 'block';
        }
    };

    componentWillUnmount(): void {
        if (typeof window.Sportsbook !== 'function') {
            return;
        }

        window.Sportsbook('kill');
        this.showSupport();
    }

    render(): React.ReactNode {
        return <div className="sport-page" id="sport-page">
        </div>;
    }
}
