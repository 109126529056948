import is24hLine from 'app/lines/is24hLine';
import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {Table} from 'app/components/table';
import {TableFilter} from 'app/components/table/filter';
import {showAllByFilters} from 'app/utils';
import IncubatorFilterButtons from 'app/components/pages/incubator/IncubatorFilterButtons';
import {sortToNotLivesLines, sortToLivesLines} from 'app/lines/linesDataForUpdate';

const FIVE_MINUTES = 300;
const DIVIDER = 1000;

const byIncubatorFilter = (bet, results = false) => {
    if (results) {
        return true;
    }

    return !bet.incubator || bet.incubator && Date.now() / DIVIDER - bet.date < FIVE_MINUTES;
};

const PlayPage = {
    commonFilter: (filters, bet, options) => {
        let res = typeof options === 'object' ? options : {};
        res = {
            only24h: false,
            onlyUpcomingMatches: false,
            results: false,
            ...res
        };
        return filters.find(filter => {
            if (res.only24h && !is24hLine(bet, res.results)) {
                return false;
            }
            if (res.currentEvents) {
                if (res.currentEvents.length !== 0 && !res.currentEvents.find(event => event === bet.tourn)) {
                    // Event != DEFAULT_EVENTS
                    return false;
                }
            }
            return res.showAllByFilters || bet.filter === filter.id && filter.val;
        });
    },
    incubatorFilter(filters, bets, options) {
        return bets.filter(bet => PlayPage.commonFilter(filters, bet, options) && bet.incubator);
    },
    liveFilter(filters, bets, options) {
        return bets.filter(bet => PlayPage.commonFilter(filters, bet, options) && bet.live && byIncubatorFilter(bet));
    },
    simpleBetsFilter(filters, bets, options) {
        return bets.filter(bet => PlayPage.commonFilter(filters, bet, options) &&
            byIncubatorFilter(bet, options.results));
    },
    simpleSportBetsFilter(filters, bets, options) {
        return bets.filter(bet => PlayPage.commonFilter(filters, bet, options) &&
            byIncubatorFilter(bet, options.results));
    },
    sportLiveFilter(filters, bets, options) {
        return bets.filter(bet => PlayPage.commonFilter(filters, bet, options) && bet.live && byIncubatorFilter(bet));
    }
};

@inject('betSlipStore', 'betsFilterStore', 'incubatorFilterStore', 'resultsFilterStore', 'userStore')
@observer
class PlayPageTable extends Component {
    componentDidMount() {
        this.props.tableStore.filterStore.updateNavigateUrl();
    }

    render() {
        const {props} = this;
        const {results} = props.tableStore;
        const {filterStore} = props.tableStore;

        const showAllFilters = props.customFilters
            ? showAllByFilters(props.customFilters)
            : props.tableStore.getShowAllFilters();
        const defaultFilters = {
            only24h: filterStore.getOnly24h(),
            onlyUpcomingMatches: filterStore.getOnlyUpcomingMatches(),
            results
        };
        const betFilters = props.customFilters || filterStore.getFilters();

        const filterProps = {...defaultFilters,
            currentEvents: filterStore.getCurrentEvents(),
            events: filterStore.getEvents(),
            filters: betFilters,
            showAllFilters,
            showMoreFilter: props.tableStore.showMoreFilter,
            tableCompact: props.tableStore.tableCompact,
            tableFilters: new Set(props.tableStore.allLines.map(b => b.filter)),
            tableStore: props.tableStore};

        const betFilterOptions = {...defaultFilters,
            currentEvents: filterStore.getCurrentEvents(),
            discipline: filterStore.discipline,
            showAllByFilters: showAllFilters};
        const tableProps = {...defaultFilters,
            activeCoef: props.activeCoef || props.betSlipStore.activeLine || null,
            bets: (props.filter || PlayPage.simpleBetsFilter)(
                betFilters,
                props.tableStore.allLines,
                betFilterOptions
            ),
            coefOnClick: props.coefOnClick,
            currentEvents: filterStore.getCurrentEvents(),
            discipline: filterStore.discipline,
            disciplines: filterStore.getDisciplines(),
            incubator: props.filter && props.filter.name === 'incubatorFilter',
            isExpress: props.isExpress,
            live: props.live,
            seo: props.seo,
            showAllFilters,
            tableCompact: props.tableStore.tableCompact,
            tableStore: props.tableStore,
            timeZoneName: window.settings.user.time_zone.name};

        if (!results && !props.tableStore.filterStore.onlyUpcomingMatches ||
            results && props.tableStore.filterStore.onlyUpcomingMatches) {
            tableProps.bets = tableProps.bets.sort((a, b) => {
                if (a.live && b.live) {
                    return sortToLivesLines(a, b, results);
                }
                if (!a.live && !b.live) {
                    return sortToNotLivesLines(a, b, false);
                }
                return !Number(a.live);
            });
        }
        return <>
            {!props.hideFilters && <TableFilter {...filterProps}/>}
            {tableProps.incubator && <IncubatorFilterButtons/>}
            <Table {...tableProps}>
                <div className="table-bets__footer-group js-table-footer">
                    {props.children}
                    <Table.Footer/>
                </div>
            </Table>
        </>;
    }
}

PlayPage.Table = PlayPageTable;

export default PlayPage;
