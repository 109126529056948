import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';

class SupportListMobile extends PureComponent {
    onClick = (): void => {
        const btn = document.getElementById('hde-chat-init-btn-1');

        if (btn) {
            btn.click();
        }
    };

    render(): ReactNode {
        return <div className="support-list__wrap">
            <div className="main-nav__subtitle">{I18n.t('support_title')}</div>
            <div className="support-list__btns">
                <button onClick={this.onClick}
                    className="support-list-btn support-list-btn_chat"
                    aria-label="Support online chat">
                </button>
                <a
                    href="https://t.me/egbcom_bot"
                    target={'_blank'}
                    rel="noreferrer"
                    className="support-list-btn support-list-btn_tg"
                    aria-label="Support online"
                >
                </a>
            </div>
        </div>;
    }
}

export default SupportListMobile;
