import {action, makeObservable, observable} from 'mobx';
import Get from 'app/utils/Get';

export class ShopLootboxStore {
    constructor() {
        makeObservable(this);
    }

    @observable lootbox = {};

    @observable animationOpenEnd = false;

    @observable openNowLootbox = false;

    @observable buyAndOpenNowLootbox = false;

    @action
    updateLootbox({lootbox}) {
        this.lootbox = lootbox;
    }

    @action
    updateAnimationStatus(value) {
        this.animationOpenEnd = value;
    }

    @action
    updateOpenNowStatus(value) {
        this.openNowLootbox = value;
    }

    @action
    updateBuyAndOpenNowStatus(value) {
        this.buyAndOpenNowLootbox = value;
    }

    async updateLootboxBySlug(slug, currency) {
        await new Get({url: `/shop/lootboxes/${slug}.json?currency=${currency || ''}`})
            .execute()
            .then(response => {
                if (!response.ok) {
                    throw response;
                }

                return response.json();
            })
            .then(lootbox => this.updateLootbox({lootbox}));
    }
}
