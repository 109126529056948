import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import SmartLoader from 'app/components/reusable/SmartLoader';
import {round2Decimal} from 'app/utils';
import ImageInterface from 'app/interfaces/ImageInterface';

interface BannerButtonPrizeProps {
    points?: number
    place?: number
    showNickHandler: React.ChangeEventHandler<HTMLInputElement>
    loading: boolean
    showName: boolean
    totalPlaceCount: number
    placeCount: number
    prize: PrizeInterface
}

export interface PrizeInterface {
        image: ImageInterface
        money_amount: number
        points_to_next_rank: number
}

const MAIN_GROUP_COUNT = 4;
const EXTRA_GROUP_COUNT = 6;

const FIRST_RANK = 1;
const SECOND_RANK = 2;
const THIRD_RANK = 3;
const FOURTH_RANK = 4;
const FIFTH_RANK = 5;

export default class BannerButtonxPrize extends Component<BannerButtonPrizeProps> {
    extraRank = (prevRank = false): string => {
        const {totalPlaceCount, placeCount, place} = this.props;
        const extraPlaceCount = placeCount - totalPlaceCount;
        const extraPlace = place - totalPlaceCount;
        let countToOnePlace = Math.floor(extraPlaceCount / EXTRA_GROUP_COUNT);
        if (countToOnePlace === 0) {
            countToOnePlace = 1;
        }

        let rank = Math.ceil(extraPlace / countToOnePlace);

        if (prevRank) {
            rank -= 1;
        }

        switch (rank) {
        case FIRST_RANK: return I18n.t('extra_ranks.candidate');
        case SECOND_RANK: return I18n.t('extra_ranks.student');
        case THIRD_RANK: return I18n.t('extra_ranks.pupil');
        case FOURTH_RANK: return I18n.t('extra_ranks.beginner');
        case FIFTH_RANK: return I18n.t('extra_ranks.contender');
        default: return I18n.t('extra_ranks.applicant');
        }
    };

    rank = (prevRank = false): string => {
        const {totalPlaceCount, place} = this.props;

        let rank = Math.ceil(place / (totalPlaceCount / MAIN_GROUP_COUNT));

        if (prevRank && rank !== FIRST_RANK) {
            rank -= 1;
        }
        switch (rank) {
        case FIRST_RANK: return I18n.t('main_ranks.superior');
        case SECOND_RANK: return I18n.t('main_ranks.chosen');
        case THIRD_RANK: return I18n.t('main_ranks.elite');
        case FOURTH_RANK: return I18n.t('main_ranks.outstanding');
        default: return this.extraRank(prevRank);
        }
    };

    render(): ReactNode {
        const {points, place, totalPlaceCount, prize: {points_to_next_rank}} = this.props;
        const onFirstPlace = points_to_next_rank === 0;
        const pointsToShow = onFirstPlace ? points : points_to_next_rank - Number(points);
        return <div className="banner-button-block leaderboard">
            <div className="banner-items-status">
                <div className="body">
                    <div className="row">
                        <div className="icon">
                            <svg className="svg-default">
                                <use xlinkHref="#svg-gold-cup"/>
                            </svg>
                            <span className="name">
                                {Number(points)} {I18n.t('points', {count: Number(points)})}
                            </span>
                        </div>
                        {!onFirstPlace && <span className="about">
                            {`${I18n.t('for_next_rank', {rank: this.rank(true)})}
                            ${pointsToShow} ${I18n.t('points', {count: Number(pointsToShow)})}`}
                        </span>}
                    </div>
                    {place <= totalPlaceCount && <div className="row">
                        <span className="name">{place}</span>
                        <span className="about">{I18n.t('table.place_title')}</span>
                    </div>}
                    <div className="row">
                        <span className="name">{this.rank()}</span>
                        <span className="about">{I18n.t('user.status_label')}</span>
                    </div>
                </div>
                <div className="checkbox-loading">
                    {this.props.loading
                        ? <SmartLoader />
                        : <div className="checkbox-block">
                            <label className="checkbox-clone" htmlFor="leader">
                                <input
                                    type="checkbox"
                                    id="leader"
                                    onChange={this.props.showNickHandler}
                                    checked={this.props.showName || false}
                                />
                                <span className="checkbox-icon"></span>
                            </label>
                            <label
                                className="about"
                                htmlFor="leader"
                            >{I18n.t('user.promo_action.compendium.show_my_nickname_in_leaderboard')}</label>
                        </div>}
                </div>
            </div>
            <div className="small-image">
                {place <= totalPlaceCount
                    ? <div className="small-image-inner">
                        <div className="image">
                            <img src={this.props.prize?.image?.url}/>
                        </div>
                    </div>
                    : <div className="shop__item">
                        <div className="preview__money">
                            <svg className="icon">
                                <use xlinkHref="#svg-money-icon" fill="none"/>
                            </svg>
                            <span className="total">${round2Decimal(this.props.prize.money_amount)}</span>
                        </div>
                    </div>}
                <span className="banner-small-image-name">{I18n.t('your_prize')}</span>
            </div>
        </div>;
    }
}
