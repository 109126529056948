import React, {PureComponent} from 'react';
import UserInventoryLink from 'app/helpers/UserInventoryLink';
import ArtifactImages from 'app/components/compendium/rating/artifact_images';
import PrizeLink from './prize_link';
import isHiddenName from 'app/utils/isHiddenName';
import InventoryItemInterface from 'app/interfaces/InventoryItemInterface';


export interface LeaderLineProps {
    place: number;
    prizes: { id: number }[];
    user_nickname: string;
    rating_points: number;
    public_inventory: boolean;
    recipes: InventoryItemInterface[];
}

export default class LeaderLine extends PureComponent<LeaderLineProps> {
    render(): JSX.Element {
        const {place, prizes, user_nickname, rating_points, public_inventory, recipes} = this.props;
        return <tr>
            <td>
                {place}
            </td>
            <td>
                <span className="user-name">
                    <UserInventoryLink
                        user_nickname={user_nickname}
                        public_inventory={!isHiddenName(user_nickname) && public_inventory}
                    />
                </span>
            </td>
            <td>
                {prizes && prizes.length > 0 && prizes.map(prize => <PrizeLink key={prize.id} {...prize}/>)
                    .reduce((prev, curr) => <>
                        {prev}
                        {' + '}
                        {curr}
                    </>)}
            </td>
            <td>
                {rating_points}
                <ArtifactImages recipes={recipes}/>
            </td>
        </tr>;
    }
}
