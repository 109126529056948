/* eslint-disable max-statements */
import React, {Component, Fragment} from 'react';
import {inject, observer} from 'mobx-react';
import {momentObj} from 'app/utils';
import daysClassComponent from './days_class_component';
import {CurrentTimeStore} from 'stores/current_time_store';
import formatTimeMinSec from './time/formatTimeMinSec';
import {getValues} from './timer/getValues';

interface TimeToStartTimerProps {
    formatMinSec?: boolean,
    countdown?: boolean,
    time_to_start: number,
    currentTimeStore?: CurrentTimeStore,
    textClass?: string,
    timerClass?: string,
    plainText?: boolean,
    format?(currentTime: number, timeToStart: number): React.ReactNode
    showColorRed?: boolean
    children?: React.ReactNode
    daysComponentName?: string
}

const SEC60 = 60;
const SEC10 = 10;

class TimeToStartTimer extends Component<TimeToStartTimerProps> {
    get diffDays() {
        const {time_to_start, currentTimeStore: {t}} = this.props;

        return Number(momentObj(time_to_start).diff(momentObj(t), 'days'));
    }

    format() {
        const daysComponentName = this.props;
        const {formatMinSec} = this.props;

        const daysComponent = daysClassComponent(daysComponentName);

        const {time_to_start, currentTimeStore: {t}, format, showColorRed} = this.props;

        const minuteTimer = showColorRed && time_to_start - t < SEC60;
        const secondTenTimer = showColorRed && time_to_start - t < SEC10;

        if (format) {
            return format(t, time_to_start);
        }

        return <span className={`span-timer${minuteTimer ? ' end-time' : ''}${secondTenTimer ? ' blink' : ''}`}>
            {daysComponent({diffDays: this.diffDays})} {
                formatMinSec
                    ? formatTimeMinSec(time_to_start - t)
                    : momentObj(time_to_start - t).format('HH:mm:ss')
            }
        </span>;
    }

    render() {
        const {
            time_to_start,
            currentTimeStore: {t},
            timerClass,
            textClass,
            children,
            plainText,
            countdown
        } = this.props;
        const daysComponent = daysClassComponent(this.props);

        if (!time_to_start) {
            return null;
        }

        if (countdown) {
            const msInSec = 1000;
            const {days} = getValues(time_to_start * msInSec);

            return <span className={textClass || 'match-details__item-timer'}>
                {days
                    ? `${daysComponent({diffDays: days})} `
                    : null}
                {momentObj(time_to_start).format('HH:mm:ss')}
            </span>;
        }

        if (time_to_start <= t) {
            if (timerClass) {
                return <div className={timerClass}>00:00:00</div>;
            }
            return <span className="end-time"> 00:00:00</span>;
        }

        return plainText
            ? this.format()
            : <Fragment>
                {children}
                <span className={textClass || 'match-details__item-timer'}>
                    {daysComponent({diffDays: this.diffDays})}
                    <div className={timerClass || 'match-details__item-time-left'}>
                        {momentObj(time_to_start - t).format('HH:mm:ss')}
                    </div>
                </span>
            </Fragment>;
    }
}

export default inject('currentTimeStore')(observer(TimeToStartTimer));
