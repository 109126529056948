import React, {Component} from 'react';
import {i18n_t} from 'app/i18n';
import AsideItem from 'site_version/com/AsideItem';

import ScheduledComponent from './compendium/scheduled_component';
import StartedComponent from './compendium/started_component';
import FinishedComponent from './compendium/finished_component';
import CompendiumBody, {HeaderPropsInterface, FooterPropsInterface} from './compendium/compendiumBody';

import {inject, observer} from 'mobx-react';
import PromoActionCompendiumInterface from 'app/interfaces/PromoActionCompendiumInterface';
import PromoActionPresentInterface from 'app/interfaces/PromoActionPresentInterface';

const SECONDS_IS_12_HOURS = 43200;

interface PropsInterface extends PromoActionCompendiumInterface, PromoActionPresentInterface {
    componentName: string,
    cookieName: string,
    currentTimeStore: {
        t: number;
    };
}

export class Compendium extends Component<PropsInterface> {
    static cookieName = 'compendium-menu';

    static componentName = 'compendium-aside';

    isEndingPromo = (): boolean => this.props.end_date - this.props.currentTimeStore.t < SECONDS_IS_12_HOURS;

    showPromo = (): boolean => this.props.results_hide_at > this.props.currentTimeStore.t;

    scatterText = (): string => i18n_t('promo_action_compendium.scatter.promo_text');

    isLeaderboardAvailable(): boolean {
        const {first_10_places_show_at, ratings, currentTimeStore: {t}} = this.props;

        return first_10_places_show_at > 0 && ratings.length > 0 && t >= first_10_places_show_at;
    }

    header = (props: HeaderPropsInterface):React.ReactNode => <CompendiumBody.Header {...props}/>;

    footer = (props: FooterPropsInterface) => (opened: boolean):React.ReactNode => <CompendiumBody.Footer {...props} opened={opened} />;

    render(): React.ReactNode {
        const {
            currentTimeStore,
            finished,
            max_points,
            money_to_next_point,
            predictions_total,
            reset_points_at,
            start_date,
            display_name,
            display_name_translations
        } = this.props;

        const componentProps = {
            ...this.props,
            currentTimeStore,
            isEndingPromo: this.isEndingPromo(),
            isLeaderboardAvailable: this.isLeaderboardAvailable(),
            scatterText: this.scatterText()
        };

        const {cookieName, componentName} = this.constructor as typeof Compendium;

        const headerProps = {display_name, display_name_translations};

        const footerProps:FooterPropsInterface = {currentTimeStore,
            finished,
            isLeaderboardAvailable: this.isLeaderboardAvailable(),
            max_points,
            money_to_next_point,
            predictions_total,
            reset_points_at,
            start_date};

        return this.showPromo() && <AsideItem
            name={cookieName}
            header={this.header(headerProps)}
            itemClassName={componentName}
            footer={this.footer(footerProps)}
        >
            <div>
                <ScheduledComponent {...componentProps} />
                <StartedComponent {...componentProps} />
                <FinishedComponent {...componentProps} />
            </div>
        </AsideItem>;
    }
}

export default inject('currentTimeStore')(observer(Compendium));
